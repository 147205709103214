import axios from "axios";

export const apiData = axios.create({
  baseURL: "/query",
  headers: {
    "Content-type": "multipart/form-data",
    timeout: 1000,
  },
});

apiData.interceptors.request.use(
  (config) => {
    let token = localData("get", "session").token;
    if (!token) {
      token = "Guest";
    }
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export function localData(action, item, value) {
  switch (action) {
    case "save":
      localStorage.setItem(item, JSON.stringify(value));
      return true;
    case "remove":
      if (localStorage.getItem(item)) {
        localStorage.removeItem(item);
        return true;
      } else {
        return false;
      }
    case "get":
      const data = localStorage.getItem(item);
      if (data) {
        return JSON.parse(data);
      } else {
        return false;
      }
    case "update":
      if (localStorage.getItem(item)) {
        localStorage.setItem(item, JSON.stringify(value));
        return true;
      } else {
        return false;
      }
    default:
      return false;
  }
}

export function cartData(action, item, value) {
  let cart = localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : {};

  switch (action) {
    case "save":
      cart[item] = value;
      localStorage.setItem("cart", JSON.stringify(cart));
      window.dispatchEvent(new Event("cartChange"));
      return true;
    case "remove":
      delete cart[item];
      localStorage.setItem("cart", JSON.stringify(cart));
      window.dispatchEvent(new Event("cartChange"));
      return true;
    case "get":
      return cart ? cart : false;
    case "check":
      return cart[item] ? cart[item] : false;
    case "update":
      if (cart[item]) {
        cart[item] = value;
        localStorage.setItem("cart", JSON.stringify(cart));
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    case "increase":
      if (cart[item]) {
        cart[item].quantity += value;
        localStorage.setItem("cart", JSON.stringify(cart));
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    case "decrease":
      if (cart[item] && cart[item].quantity > 1) {
        cart[item].quantity -= value;
        localStorage.setItem("cart", JSON.stringify(cart));
        window.dispatchEvent(new Event("cartChange"));
        return true;
      }
      return false;
    default:
      return false;
  }
}

export async function runData(data, path, type) {
  try {
    let formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }

    let config = {};
    if (type) {
      config.responseType = type;
    }

    const response = await apiData.post(path, formData, config);
    localData("remove", "error");
    return {
      response: response,
    };
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localData("remove", "session");
      localData("remove", "expiry");
      localData("remove", "error");
    } else if (error.response && error.response.status === 503) {
      window.location.reload();
    } else if (error.response && error.response.status === 502) {
      localData("save", "error", true);
    }

    return {
      error: error,
    };
  }
}

export async function getData(data, path, type) {
  try {
    let formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }

    let config = {};
    if (type) {
      config.responseType = type;
    }

    const response = await apiData.get(path, formData, config);

    return {
      response: response,
    };
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localData("remove", "session");
      localData("remove", "expiry");
    } else if (error.response && error.response.status === 503) {
      window.location.reload();
    } else if (error.response && error.response.status === 502) {
      localData("save", "error", true);
    }
    return {
      error: error,
    };
  }
}
