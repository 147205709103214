import { useState, useEffect } from "react";
import { localData, runData } from "../backend";

export function useCheckActivity(minutes) {
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const setUserActivity = () => {
      const time = new Date().getTime();
      localData("save", "expiry", time);
    };
    const sendSession = async () => {
      const token = localData("get", "session").token;
      if (token) {
        const data = {};
        await runData(data, `/query/session/`);
      }
    };
    const checkInactivity = () => {
      const storedTime = localData("get", "expiry");
      const currentTime = new Date().getTime();
      const specifiedMinutes = minutes * 60 * 1000;

      if (currentTime - storedTime > specifiedMinutes) {
        setIsActive(false);
      } else {
        setIsActive(true);
        sendSession();
      }
    };

    window.addEventListener("mousemove", setUserActivity);
    window.addEventListener("keypress", setUserActivity);
    window.addEventListener("touchstart", setUserActivity);

    const interval = setInterval(checkInactivity, 1);

    return () => {
      window.removeEventListener("mousemove", setUserActivity);
      window.removeEventListener("keypress", setUserActivity);
      window.removeEventListener("touchstart", setUserActivity);
      clearInterval(interval);
    };
  }, [minutes]);

  return isActive;
}
