import { useEffect, useState } from "react";
import { localData } from "../backend";
import note from "../../aud/button.mp3";

const useMessageCount = () => {
  const [messageCount, setMessageCount] = useState(
    localData("get", "unread") || 0
  );

  const sound = new Audio(note);

  useEffect(() => {
    const updateMessageCount = () => {
      const count = localData("get", "unread");
      const newCount = count ? Number(count) : 0;

      if (newCount > messageCount) {
        sound
          .play()
          .catch((error) => console.error("Failed to play sound:", error));
      }

      setMessageCount(newCount);
    };

    updateMessageCount();

    const intervalId = setInterval(updateMessageCount, 1);

    window.addEventListener("storage", updateMessageCount);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("storage", updateMessageCount);
    };
  }, [messageCount]);

  return messageCount;
};

export { useMessageCount };
