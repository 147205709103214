import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Top from "../includes/topping";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { runData } from "../../jsx/backend";
import Loader from "../includes/loader";
import MarketingImg from "../../img/bg.jpg";

Modal.setAppElement("#root");

const Terms = () => {
  const [title, setTitle] = useState("Loading");
  const [description, setDescription] = useState("Loading");
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);
  const [loading, setLoading] = useState(true);

  const pageTitle = async () => {
    const data = {
      page: "terms",
    };
    const responseData = await runData(data, `/page/`);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTitle(responseData.response.data.title);
          setDescription(responseData.response.data.description);
          setLoading(false);
          break;

        default:
          setMessage(responseData.response.data.message);
          setTitle(responseData.response.data.message);
          setLoading(false);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setTitle(responseData.error.message);
      setLoading(false);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setTitle("An error occured while processing your request.");
      setLoading(false);
      setReporting(true);
    }
  };

  useEffect(() => {
    pageTitle();
  }, []);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <div
      style={{
        minHeight: "75VH",
      }}
    >
      <Helmet>
        <title>{title} - Marmasco</title>
      </Helmet>
      {loading ? (
        <div
          className="marmasco-white"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="marmasco-animate-top">
          <div
            style={{
              backgroundImage: `url(${MarketingImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.85)",
                minHeight: "100%",
                width: "100%",
              }}
            >
              <div
                className="marmasco-container marmasco-center marmasco-text-white"
                style={{
                  marginTop: "-50px",
                  paddingTop: "90px",
                  paddingBottom: "90px",
                }}
              >
                <h1 style={{ textTransform: "uppercase" }}>{title}</h1>
                <p>{description}</p>
              </div>
            </div>
          </div>

          <div
            className="marmasco-container marmasco-content"
            style={{
              paddingTop: "120px",
              paddingBottom: "100px",
              height: "100%",
            }}
          >
            <h4>1. Introduction</h4>

            <p>
              These terms and conditions ("Terms") govern your use of our
              website and services ("Services"). By using our Services, you
              accept these Terms in full. If you disagree with these Terms or
              any part of these Terms, you must not use our Services.
            </p>

            <h4>2. Intellectual Property Rights</h4>

            <p>
              Unless otherwise stated, we or our licensors own the intellectual
              property rights in the website and material on the website.
              Subject to the license below, all these intellectual property
              rights are reserved.
            </p>

            <h4>3. License to Use Website</h4>

            <p>
              You may view, download for caching purposes only, and print pages
              from the website for your own personal use, subject to the
              restrictions set out below and elsewhere in these Terms.
            </p>

            <h4>4. Acceptable Use</h4>

            <p>
              You must not use our Services in any way that causes, or may
              cause, damage to the Services or impairment of the availability or
              accessibility of the Services; or in any way which is unlawful,
              illegal, fraudulent or harmful, or in connection with any
              unlawful, illegal, fraudulent or harmful purpose or activity.
            </p>

            <h4>5. User Content</h4>

            <p>
              In these Terms, "your user content" means material (including
              without limitation text, images, audio material, video material
              and audio-visual material) that you submit to our Services, for
              whatever purpose. You grant to us a worldwide, irrevocable,
              non-exclusive, royalty-free license to use, reproduce, adapt,
              publish, translate and distribute your user content in any
              existing or future media.
            </p>

            <h4>6. No Warranties</h4>

            <p>
              This website is provided “as is” without any representations or
              warranties, express or implied. We make no representations or
              warranties in relation to this website or the information and
              materials provided on this website.
            </p>
            <h4>7. Limitations and Exclusions of Liability</h4>

            <p>
              Our liability to you in relation to the use of our website or
              under or in connection with these Terms, whether in contract, tort
              (including negligence) or otherwise, will be limited as follows:
              we will not be liable for any consequential, indirect or special
              loss or damage; we will not be liable for any loss of profit,
              income, revenue, anticipated savings, contracts, business,
              goodwill, reputation, data, or information.
            </p>

            <h4>8. Indemnity</h4>

            <p>
              You hereby indemnify us and undertake to keep us indemnified
              against any losses, damages, costs, liabilities and expenses
              (including without limitation legal expenses and any amounts paid
              by us to a third party in settlement of a claim or dispute on the
              advice of our legal advisers) incurred or suffered by us arising
              out of any breach by you of any provision of these Terms.
            </p>

            <h4>9. Breaches of These Terms</h4>

            <p>
              Without prejudice to our other rights under these Terms, if you
              breach these Terms in any way, we may take such action as we deem
              appropriate to deal with the breach, including suspending your
              access to the Services, prohibiting you from accessing the
              Services, blocking computers using your IP address from accessing
              the Services, contacting your internet service provider to request
              that they block your access to the Services and/or bringing court
              proceedings against you.
            </p>

            <h4>10. Variation</h4>

            <p>
              We may revise these Terms from time to time. Revised Terms will
              apply to the use of our Services from the date of the publication
              of the revised Terms on our website.
            </p>

            <h4>11. Assignment</h4>

            <p>
              We may transfer, sub-contract or otherwise deal with our rights
              and/or obligations under these Terms without notifying you or
              obtaining your consent.
            </p>

            <h4>12. Severability</h4>

            <p>
              If a provision of these Terms is determined by any court or other
              competent authority to be unlawful and/or unenforceable, the other
              provisions will continue in effect.
            </p>

            <h4>13. Entire Agreement</h4>

            <p>
              These Terms, together with our Privacy Policy, constitute the
              entire agreement between you and us in relation to your use of our
              Services and supersede all previous agreements in respect of your
              use of our Services.
            </p>

            <h4>14. Law and Jurisdiction</h4>

            <p>
              These Terms will be governed by and construed in accordance with
              Zimbabwean law, and any disputes relating to these Terms will be
              subject to the exclusive jurisdiction of the courts of Zimbabwe.
            </p>
          </div>
          <div
            className="marmasco-container"
            style={{
              paddingTop: "50px",
              paddingBottom: "80px",
            }}
          ></div>

          <Modal
            isOpen={report}
            onRequestClose={() => setReporting(false)}
            style={customStyles}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#f8f9fa",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              }}
            >
              <h4>
                Message{" "}
                <span
                  onClick={() => setReporting(false)}
                  title="Close"
                  className="marmasco-right"
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faTimes} color="red" />
                </span>
              </h4>

              <hr />

              <p className="marmasco-center">{message}</p>

              <br />
              <button
                className="marmasco-btn marmasco-red"
                onClick={() => setReporting(false)}
                style={{ borderRadius: "30px", width: "100%" }}
              >
                CLOSE
              </button>
            </div>
          </Modal>

          <Top />
        </div>
      )}
    </div>
  );
};

export default Terms;
