import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Top from "../../includes/topping";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faCommentDots,
  faExchangeAlt,
  faGlobe,
  faTimes,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { runData } from "../../../jsx/backend";
import Loader from "../../includes/loader";
import DomainsImg from "../../../img/domains.jpg";
import DomainImg from "../../../img/domains.png";
import BgImg from "../../../img/bg.png";
import SearchDomain from "../../sections/searchDomain";
import ParticlesBackground from "../../includes/background";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { useMessageCount } from "../../../jsx/hooks/messages";

Modal.setAppElement("#root");

const Domains = () => {
  const [title, setTitle] = useState("Loading");
  const [description, setDescription] = useState("Loading");
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);
  const [loading, setLoading] = useState(true);
  const messageCount = useMessageCount();

  const pageTitle = async () => {
    const data = {
      page: "domains",
    };
    const responseData = await runData(data, `/page/`);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTitle(responseData.response.data.title);
          setDescription(responseData.response.data.description);
          setLoading(false);
          break;

        default:
          setMessage(responseData.response.data.message);
          setTitle(responseData.response.data.message);
          setLoading(false);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setTitle(responseData.error.message);
      setLoading(false);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setTitle("An error occured while processing your request.");
      setLoading(false);
      setReporting(true);
    }
  };

  useEffect(() => {
    pageTitle();
  }, []);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <div
      style={{
        minHeight: "75VH",
        backgroundImage: `url(${BgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Helmet>
        <title>{title} - Marmasco</title>
      </Helmet>
      <ParticlesBackground />
      {loading ? (
        <div
          className="marmasco-white"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="marmasco-animate-top">
          <div
            style={{
              backgroundImage: `url(${DomainsImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.85)",
                minHeight: "100%",
                width: "100%",
              }}
            >
              <div
                className="container-fluid marmasco-center marmasco-text-white"
                style={{
                  marginTop: "-50px",
                  paddingTop: "90px",
                  paddingBottom: "90px",
                }}
              >
                <h1 style={{ textTransform: "uppercase" }}>{title}</h1>
                <p>{description}</p>
              </div>
            </div>
          </div>

          <div
            className="container-fluid marmasco-padding-large"
            style={{
              marginTop: "150px",
            }}
          >
            <div className="row">
              <SearchDomain />
            </div>
          </div>

          <div className="container-fluid" style={{ marginTop: "150px" }}>
            <div className="row marmasco-padding-large">
              <div className="col-md-6">
                
                <div className="marmasco-justify">
                  <p>
                    At Marmasco, we understand the importance of a strong online
                    presence in today's digital age. A domain name is more than
                    just an address on the internet; it's a powerful tool that
                    can help you build your brand, reach a global audience, and
                    drive business growth. That's why we're committed to
                    providing you with superior domain services that are not
                    only reliable but also tailored to your specific needs.
                  </p>

                  <p>
                    Our domain registration process is designed to be quick,
                    easy, and affordable. We offer a wide range of domain
                    extensions, from the popular .co.zw, .com and .net to niche
                    extensions that cater to specific industries and regions.
                    Whether you're a small business owner looking to establish
                    your online presence or a large corporation aiming to expand
                    your digital footprint, we can help you find the perfect
                    domain name that aligns with your brand and business goals.
                  </p>

                  <p>
                    If you already have a domain name but are not satisfied with
                    your current provider, our domain transfer service is the
                    perfect solution. We ensure a smooth transition with minimal
                    downtime, so your online operations never skip a beat. Our
                    team of experts will handle all the technical aspects of the
                    transfer, leaving you free to focus on what you do best -
                    running your business.
                  </p>

                  <p>
                    We also offer a user-friendly DNS management interface that
                    gives you full control over your domain settings. From
                    setting up subdomains and email servers to configuring DNS
                    records, we make it easy for you to manage your domain. And
                    if you ever need help, our dedicated support team is just a
                    call or email away.
                  </p>
                  <p>
                    But we don't stop at providing great domain services. We
                    understand that the security of your domain is paramount.
                    That's why we offer robust security measures, including
                    domain lock and WHOIS privacy protection, to safeguard your
                    domain from potential threats. With Superior Domain
                    Services, you can rest assured that your domain is in safe
                    hands.
                  </p>
                </div>
              </div>
              <div className="col-md-6 grayscale">
                <img
                  src={DomainImg}
                  alt="Domains"
                  className="centered-image"
                  style={{
                    height: "500px",
                    maxWidth: "100%",
                    objectFit: "contain",
                    borderRadius: "10px",
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className="container-fluid  marmasco-container"
            style={{
              paddingBottom: "80px",
              marginTop: "150px",
            }}
          >
            <div className="flex-container">
              <div className="flex-item">
                <FontAwesomeIcon icon={faGlobe} size="5x" color="#009688" />
                <br />
                <h2>Domain Registration</h2>
                <br />
                <p>Register your domain with us and enjoy seamless service.</p>
              </div>
              <div className="flex-item">
                <FontAwesomeIcon
                  icon={faExchangeAlt}
                  size="5x"
                  color="#009688"
                />
                <br />
                <h2>Domain Transfer</h2>
                <br />
                <p>Transfer your existing domain to us without any hassle.</p>
              </div>
              <div className="flex-item">
                <FontAwesomeIcon icon={faCog} size="5x" color="#009688" />
                <br />
                <h2>DNS Management</h2>
                <br />
                <p>
                  Manage your DNS settings with our user-friendly interface.
                </p>
              </div>
              <div className="flex-item">
                <FontAwesomeIcon
                  icon={faUserShield}
                  size="5x"
                  color="#009688"
                />
                <br />
                <h2>Security</h2>
                <br />
                <p>Enjoy top-notch security for your domains with us.</p>
              </div>
            </div>
          </div>

          <Modal
            isOpen={report}
            onRequestClose={() => setReporting(false)}
            style={customStyles}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#f8f9fa",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              }}
            >
              <h4>
                Message{" "}
                <span
                  onClick={() => setReporting(false)}
                  title="Close"
                  className="marmasco-right"
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faTimes} color="red" />
                </span>
              </h4>

              <hr />

              <p className="marmasco-center">{message}</p>

              <br />
              <button
                className="marmasco-btn marmasco-red"
                onClick={() => setReporting(false)}
                style={{ borderRadius: "30px", width: "100%" }}
              >
                CLOSE
              </button>
            </div>
          </Modal>
          <Top />
        </div>
      )}
    </div>
  );
};

export default Domains;
