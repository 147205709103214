import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink } from "react-router-dom";
import Switch from "react-switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCartShopping,
  faCog,
  faCogs,
  faDashboard,
  faGlobe,
  faHomeLgAlt,
  faKey,
  faLightbulb,
  faMessage,
  faMinus,
  faMoon,
  faPlus,
  faServer,
  faSignInAlt,
  faSignOutAlt,
  faSun,
  faTrash,
  faUserAlt,
  faUserCog,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { cartData, localData } from "../../jsx/backend";
import logo from "../../img/logo.png";
import {
  Badge,
  Card,
  CardFooter,
  Col,
  Dropdown,
  ListGroup,
  Nav,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap";

const Header = () => {
  const [searchState, setStateSearch] = useState(false);
  const [theme, setTheme] = useState("light");
  const darkMode = theme === "dark";
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cartTotal, setTaskTotal] = useState(0);
  const [show, setShow] = useState(false);
  const [cartItems, setCartItems] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSearchInput = async () => {
    setIsLoading(true);
    setStateSearch(true);
    // Call your search function here and wait for it to finish
    // After the search function finishes, set isLoading to false
    //setIsLoading(false);
  };

  const handleCancelSearch = () => {
    setSearchInput("");
    setStateSearch(false);
    setIsLoading(false);
  };

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localData("save", "theme", newTheme);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
    if (event.target.value.length < 1) {
      setStateSearch(false);
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    localData("remove", "messages");
    localData("remove", "expiry");
    localData("remove", "session");
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const items = cartData("get");
      setCartItems(items);
    }, 1);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const renderCartItems = () => {
    return Object.keys(cartItems).map((key) => {
      const item = cartItems[key];
      return (
        <ListGroup.Item
          key={key}
          className="mm-small"
          style={{ marginBottom: "30px" }}
        >
          <Row
            className="d-flex justify-content-between"
            style={{ marginBottom: "10px" }}
          >
            <Col xs={9} className="d-flex align-items-center">
              {item.product}
            </Col>
            <Col xs={3} className="d-flex justify-content-end">
              <Button
                variant="danger"
                size="sm"
                onClick={() => cartData("remove", key)}
                title="Delete"
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between align-items-center">
            <Col xs={9} className="d-flex align-items-center">
              <Button
                variant="success"
                size="sm"
                onClick={() => cartData("increase", key, 1)}
                title="Increase Quantity"
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
              <span className="mx-2">{item.quantity}</span>
              <Button
                variant="warning"
                size="sm"
                onClick={() => cartData("decrease", key, 1)}
                title="Decrease Quantity"
              >
                <FontAwesomeIcon icon={faMinus} />
              </Button>
            </Col>
            <Col xs={3} className="d-flex justify-content-end">
              ${item.price * item.quantity}
            </Col>
          </Row>
        </ListGroup.Item>
      );
    });
  };

  const calculateTotalPrice = () => {
    return Object.values(cartItems).reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        sticky="top"
        className="bg-body-tertiary px-3 py-2 marmasco-card marmasco-light-grey justify-content-between"
      >
        <Button
          variant="light"
          onClick={handleShow}
          className="me-2 order-lg-1 position-relative"
        >
          <FontAwesomeIcon icon={faCartShopping} size="2x" color="grey" />
          <Badge
            pill
            variant="danger"
            bg="danger"
            className="position-absolute top-0 translate-middle p-2"
            style={{ transform: "translate(70%, -50%)", fontSize: "0.6rem" }}
          >
            {Object.keys(cartItems).length}
          </Badge>
        </Button>
        <Navbar.Brand href="/" className="mx-auto order-lg-0">
          <div className="logo">
            <div>
              <img
                src={`${logo}`}
                alt="MENU"
                style={{
                  borderWidth: "3px !important",
                  flexShrink: 0,
                  maxWidth: "100%",
                  maxHeight: "40px",
                }}
              />{" "}
            </div>
          </div>
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="navbarScroll"
          className="order-lg-2 no-outline btn bg-white"
        />
        <Navbar.Collapse id="navbarScroll" className="ml-3 ml-md-0">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "300px" }}
            navbarScroll
          >
            <Nav.Link
              className="d-none d-md-block"
              style={{ width: "150px" }}
            ></Nav.Link>
            <Nav.Link as={NavLink} to="/" className="nav-link custom-nav-link">
              <FontAwesomeIcon icon={faHomeLgAlt} /> Home
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/domains/"
              className="nav-link custom-nav-link"
            >
              <FontAwesomeIcon icon={faGlobe} /> Domains
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/hosting/"
              className="nav-link custom-nav-link"
            >
              <FontAwesomeIcon icon={faServer} /> Hosting
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/marketing/"
              className="nav-link custom-nav-link"
            >
              <FontAwesomeIcon icon={faLightbulb} /> Marketing
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/systems/"
              className="nav-link custom-nav-link"
            >
              <FontAwesomeIcon icon={faCogs} /> Systems
            </Nav.Link>
            <Nav.Link
              as={NavLink}
              to="/resources/"
              className="nav-link custom-nav-link"
            >
              <FontAwesomeIcon icon={faBook} /> Resources
            </Nav.Link>
          </Nav>
          <div style={{ position: "relative" }}>
            <Dropdown.Menu
              style={{ width: "100%", overflowWrap: "break-word" }}
              className="marmasco-padding"
              show={searchState}
            >
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Spinner animation="border" />
                </div>
              ) : (
                searchResults.map((result, index) => (
                  <NavLink
                    key={index}
                    to={result.link}
                    style={{ wordBreak: "break-all" }}
                  >
                    <p>{result.snippet}</p>
                  </NavLink>
                ))
              )}
            </Dropdown.Menu>
            <Form className="d-flex marmasco-padding">
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                {localData("get", "session")  &&
                <NavLink to={`/dashboard/`}>
                <FontAwesomeIcon icon={faDashboard} size="2x" color="grey" style={{cursor: "pointer"}}/>
                </NavLink>
                }
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    className="marmasco-color no-outline"
                    id="dropdown-basic"
                    style={{ marginRight: "15px" }}
                  >
                    <FontAwesomeIcon icon={faUserAlt} /> Account
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu-left">
                    <NavLink to="/login/" className="dropdown-item">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span>Login</span>
                        <FontAwesomeIcon icon={faSignInAlt} />
                      </div>
                    </NavLink>
                    
                    <Dropdown.Divider />
                    <NavLink
                      to="/reset/"
                      className="dropdown-item marmasco-tiny"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span>Reset Password</span>
                        <FontAwesomeIcon icon={faKey} />
                      </div>
                    </NavLink>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <Form.Control
                type="search"
                placeholder="Search..."
                className="me-2 no-outline"
                aria-label="Search"
                value={searchInput}
                onChange={handleSearchInputChange}
              />
              <Button
                variant="light"
                className="me-2 marmasco-color-outline"
                onClick={handleSearchInput}
                disabled={!searchInput}
              >
                {isLoading ? "Searching..." : "Search"}
              </Button>
              {searchInput && (
                <Button
                  variant="outline-danger"
                  className="me-2"
                  onClick={handleCancelSearch}
                >
                  Cancel
                </Button>
              )}
            </Form>
          </div>
        </Navbar.Collapse>
      </Navbar>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton className="marmasco-light-grey">
          <Offcanvas.Title>
            <Badge bg="danger">{Object.keys(cartItems).length}</Badge> SHOPPING
            CART
          </Offcanvas.Title>{" "}
        </Offcanvas.Header>
        <Offcanvas.Body
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div style={{ marginTop: "30px" }}>
            {Object.keys(cartItems).length > 0 && (
              <Card id="cart">
                <Card.Body>
                  <ListGroup variant="flush">{renderCartItems()}</ListGroup>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between align-items-center">
                  <Card.Text className="mb-0">
                    Total Price:{" "}
                    <span>{calculateTotalPrice().toFixed(2)} USD</span>
                  </Card.Text>
                  <Link to="/checkout/">
                    <Button variant="success" className="marmasco-color">
                      CHECKOUT
                    </Button>
                  </Link>
                </Card.Footer>
              </Card>
            )}
          </div>
          <div
            className="marmasco-padding marmasco-center"
            style={{ marginTop: "30px" }}
          >
            <span style={{ textAlign: "center" }}>
              &copy;{new Date().getFullYear()} Marmasco Technologies Pvt Ltd
            </span>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
