import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Top from "../includes/topping";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faClock,
  faCogs,
  faEnvelope,
  faMapLocation,
  faPhoneAlt,
  faTimes,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { runData } from "../../jsx/backend";
import Loader from "../includes/loader";
import MarketingImg from "../../img/bg.jpg";
import BgImg from "../../img/bg.png";
import ParticlesBackground from "../includes/background";
import { useMessageCount } from "../../jsx/hooks/messages";
import { Button } from "react-bootstrap";

Modal.setAppElement("#root");

const Contact = () => {
  const [title, setTitle] = useState("Loading");
  const [description, setDescription] = useState("Loading");
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);
  const [loading, setLoading] = useState(true);
  const messageCount = useMessageCount();

  const [name, setName] = useState(localStorage.getItem("name") || "");
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [phone, setPhone] = useState(localStorage.getItem("phone") || "");

  useEffect(() => {
    localStorage.setItem("name", name);
    localStorage.setItem("email", email);
    localStorage.setItem("phone", phone);
  }, [name, email, phone]);

  const pageTitle = async () => {
    const data = {
      page: "contact",
    };
    const responseData = await runData(data, `/page/`);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTitle(responseData.response.data.title);
          setDescription(responseData.response.data.description);
          setLoading(false);
          break;

        default:
          setMessage(responseData.response.data.message);
          setTitle(responseData.response.data.message);
          setLoading(false);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setTitle(responseData.error.message);
      setLoading(false);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setTitle("An error occured while processing your request.");
      setLoading(false);
      setReporting(true);
    }
  };

  useEffect(() => {
    pageTitle();
  }, []);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <div
      style={{
        minHeight: "75VH",
        backgroundImage: `url(${BgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Helmet>
        <title>{title} - Marmasco</title>
      </Helmet>
      <ParticlesBackground />
      {loading ? (
        <div
          className="marmasco-white"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="marmasco-animate-top">
          <div
            style={{
              backgroundImage: `url(${MarketingImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.85)",
                minHeight: "100%",
                width: "100%",
              }}
            >
              <div
                className="marmasco-container marmasco-center marmasco-text-white"
                style={{
                  marginTop: "-50px",
                  paddingTop: "90px",
                  paddingBottom: "90px",
                }}
              >
                <h1 style={{ textTransform: "uppercase" }}>{title}</h1>
                <p>{description}</p>
              </div>
            </div>
          </div>

          <div
            className="container-fluid marmasco-padding-large"
            style={{ marginTop: "150px" }}
          >
            <div className="row">
              <div className="col-md-6">
                <h1>Contact Details</h1>
                <h4>
                  <FontAwesomeIcon icon={faPhoneAlt} color="grey" /> Phone
                  Numbers
                </h4>
                <ul>
                  <li>+263 78 605 8024 (WhatsApp Bot & Calls)</li>
                  <li>+263 71 460 2698 (WhatsApp Chat & Calls)</li>
                </ul>
                <h4>
                  <FontAwesomeIcon icon={faEnvelope} color="grey" /> Email
                  Addresses
                </h4>
                <ul>
                  <li>support@marmasco.com (Technical Support)</li>
                  <li>finance@marmasco.com (Billing Support)</li>
                </ul>

                <h4>
                  <FontAwesomeIcon icon={faMapLocation} color="grey" /> Physical
                  Address
                </h4>
                <ul>
                  <li>15992 Unit P, Seke, Chitungwiza - Zimbabwe</li>
                </ul>
              </div>

              <div className="col-md-6 marmasco-justify">
                <p>
                  At Marmasco Technologies Private Limited, we are dedicated to
                  providing exceptional support to all our clients. Our standard
                  support hours are from 8am to 5pm during weekdays. We
                  understand the importance of timely assistance, hence our team
                  is always ready to help you within these hours. However, we
                  also offer a Premium Support service for our account holders.
                  If you have subscribed to our Premium Support, you have the
                  privilege of reaching out to us at any time, day or night.
                  This service is designed to provide you with round-the-clock
                  assistance, ensuring that your operations run smoothly without
                  any interruptions. We are committed to your success and are
                  always here to help you navigate any challenges you may
                  encounter.
                </p>
                <a
                  href="https://maps.app.goo.gl/P8pFrHJkJwgCrosm8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    className="btn-block btn-secondary"
                    style={{ marginTop: "50px", width: "100%" }}
                  >
                    Visit Marmasco
                  </Button>
                </a>
              </div>
            </div>
          </div>

          <div
            className="container-fluid marmasco-container"
            style={{
              paddingTop: "50px",
              paddingBottom: "80px",
              marginTop: "70px",
            }}
          >
            <div className="flex-container">
              <div className="flex-item">
                <FontAwesomeIcon icon={faClock} size="5x" color="#009688" />
                <h2>Availability</h2>
                <p>
                  We're available weekdays 5am-5pm. Premium support offers 24/7
                  assistance.
                </p>
              </div>
              <div className="flex-item">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="5x"
                  color="#009688"
                />
                <h2>Reliability</h2>
                <p>Our team is dependable. We're committed to your success.</p>
              </div>
              <div className="flex-item">
                <FontAwesomeIcon icon={faUserTie} size="5x" color="#009688" />
                <h2>Professionalism</h2>
                <p>
                  We maintain a high standard of professionalism. Your
                  satisfaction is our priority.
                </p>
              </div>
              <div className="flex-item">
                <FontAwesomeIcon icon={faCogs} size="5x" color="#009688" />
                <h2>Technical</h2>
                <p>
                  We provide expert technical assistance. We're here to help
                  navigate challenges.
                </p>
              </div>
            </div>
          </div>

          <Modal
            isOpen={report}
            onRequestClose={() => setReporting(false)}
            style={customStyles}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#f8f9fa",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              }}
            >
              <h4>
                Message{" "}
                <span
                  onClick={() => setReporting(false)}
                  title="Close"
                  className="marmasco-right"
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faTimes} color="red" />
                </span>
              </h4>

              <hr />

              <p className="marmasco-center">{message}</p>

              <br />
              <button
                className="marmasco-btn marmasco-red"
                onClick={() => setReporting(false)}
                style={{ borderRadius: "30px", width: "100%" }}
              >
                CLOSE
              </button>
            </div>
          </Modal>

          <Top />
        </div>
      )}
    </div>
  );
};

export default Contact;
