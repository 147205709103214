import React from 'react';
import { Helmet } from 'react-helmet';

const ServerError = () => {
    const styles = {
        container: {
            textAlign: 'center',
            margin: 0,
            padding: 0,
            background: '#262626',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            fontFamily: '"Arial", sans-serif',
            color: '#fff',
        },
        h1: {
            fontSize: '50px',
            marginBottom: '20px',
        },
        p: {
            fontSize: '20px',
        },
    };

    return (
        <div style={styles.container}>
            <Helmet>
                <title>Service Temporarily Unavailable</title>
                <meta http-equiv="refresh" content="30" />
            </Helmet>
            <h1 style={styles.h1}>Our Service is Temporarily Unavailable</h1>
            <p style={styles.p}>We are currently experiencing technical difficulties. Our team has been notified and is working diligently to resolve the issue. We appreciate your patience and understanding.</p>
        </div>
    );
};

export default ServerError;