import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleUp, faEnvelope, faPhone, faX } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const Footer = () => {
const [visible, setVisible] = useState(false);

const toggleVisible = () => {
const scrolled = document.documentElement.scrollTop;
if (scrolled > 80){
setVisible(true)
} else if (scrolled <= 80){
setVisible(false)
}};
const scrollToTop = () =>{
window.scrollTo({
top: 0, 
behavior: 'smooth'
});};
window.addEventListener('scroll', toggleVisible);

return (
<footer className="marmasco-container marmasco-color marmasco-center foot" >
<div className="marmasco-container diagonal-split-background-black" style={{border: "1px solid #009688", borderRadius: "10px"}}>
<div className='marmasco-half'>
<div className='marmasco-display-container' style={{height: "80px", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
  <div className='marmasco-display-middle' style={{display: 'flex', justifyContent: 'space-between', width: '80%', flexWrap: "wrap"}}>
    <a href="https://www.facebook.com/marmasco" target="_blank" rel="noopener noreferrer" className='marmasco-padding zoom-on-hover' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#3b5998', color: '#fff', margin: '5px', borderRadius: '10px', flexDirection: "column"}}>
      <FontAwesomeIcon icon={faFacebook} />
    </a>
    <a href="https://twitter.com/marmascoTechnologies" target="_blank" rel="noopener noreferrer" className='marmasco-padding zoom-on-hover' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#00acee', color: '#fff', margin: '5px', borderRadius: '10px', flexDirection: "column"}}>
      <FontAwesomeIcon icon={faX} />
    </a>
    <a href="https://wa.me/+263714602698" target="_blank" rel="noopener noreferrer" className='marmasco-padding zoom-on-hover' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#25D366', color: '#fff', margin: '5px', borderRadius: '10px', flexDirection: "column"}}>
      <FontAwesomeIcon icon={faWhatsapp} />
    </a>
    <a href="https://t.me/marmasco"  target="_blank" rel="noopener noreferrer" className='marmasco-padding zoom-on-hover' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#0088cc', color: '#fff', margin: '5px', borderRadius: '10px', flexDirection: "column"}}>
  <FontAwesomeIcon icon={faTelegram} />
</a>
<a href="tel:+263786058024" className='marmasco-padding zoom-on-hover' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#000', color: '#fff', margin: '5px', borderRadius: '10px', flexDirection: "column"}}>
      <FontAwesomeIcon icon={faPhone} />
    </a>
    <a href="mailto:support@marmasco.com" className='marmasco-padding zoom-on-hover' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#333', color: '#fff', margin: '5px', borderRadius: '10px', flexDirection: "column"}}>
      <FontAwesomeIcon icon={faEnvelope} />
    </a>
  </div>
  </div>
</div>
<div className='marmasco-half'>
<div className='marmasco-display-container' style={{height: "80px"}}>
<div className='marmasco-display-middle'>
<a href="https://wa.me/+263786058024" target="_blank" rel="noopener noreferrer"><button className='marmasco-btn marmasco-hover-white zoom-on-hover' style={{borderRadius: "30px", background: 'rgb(37, 211, 102)'}}>WhatsApp Bot <FontAwesomeIcon icon={faWhatsapp} /></button></a>
</div>
</div>
</div>
</div>
<br />
<div className="marmasco-col s6 marmasco-row-padding">
<div className="marmasco-third">
<p className='zoom-on-hover'><Link to={"/about/"}>About Us</Link></p>
</div><div className="marmasco-third">
<p className='zoom-on-hover'><Link to={"/contact/"}>Contact Us</Link></p>
</div>

<div className="marmasco-third">
<p className='zoom-on-hover'><Link to={"/resources/"}>Resources</Link></p>
</div>
</div>
<div className="marmasco-col s6 marmasco-row-padding">
<div className="marmasco-third">
<p className='zoom-on-hover'><Link to={"/terms/"}>Terms & Conditions</Link></p>
</div>
<div className="marmasco-third">
<p className='zoom-on-hover'><Link to={"/privacy/"}>Privacy Policy</Link></p>
</div>
<div className="marmasco-third">
<p className='zoom-on-hover'><Link to={"/refund/"}>Refund Policy</Link></p>
</div>
</div>
<br />
<br />
<br />
<p className='marmasco-text-black'>&copy;2015 - {new Date().getFullYear()} Marmasco Technologies (PVT) LTD - All Rights Reserved</p>
<button onClick={scrollToTop} style={{display: visible ? 'inline' : 'none', borderRadius: "30px"}} className="marmasco-scroll-up marmasco-teal marmasco-btn zoom-on-hover" title="Scroll Up"><FontAwesomeIcon icon={faArrowAltCircleUp} /></button>

</footer>
)};

export default Footer;