import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { apiData, localData, runData } from "../../jsx/backend";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faTimes,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import getIcon from "..//includes/icons";
import { Button, Dropdown, FloatingLabel, Form } from "react-bootstrap";
import bgImg from "../../img/bg.jpg";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";

Modal.setAppElement("#root");

function Recover() {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(null);
  let { token } = useParams();
  const [report, setReporting] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [isValid, setIsValid] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });
  const [showChat, setShowChat] = useState(localData("get", "chatting"));

  const handleInfoToggle = () => {
    setShowInfo(!showInfo);
  };

  const [title, setTitle] = useState("Loading");

  const pageTitle = async () => {
    const data = {
      page: "reset",
    };
    const responseData = await runData(data, `/page/`);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTitle(responseData.response.data.title);
          setLoading(false);
          break;

        default:
          setMessage(responseData.response.data.message);
          setTitle(responseData.response.data.message);
          setLoading(false);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setTitle(responseData.error.message);
      setLoading(false);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setTitle("An error occured while processing your request.");
      setLoading(false);
      setReporting(true);
    }
  };

  useEffect(() => {
    pageTitle();
  }, []);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    const value = event.target.value;

    setIsValid({
      length: value.length >= 8,
      lowercase: /[a-z]/.test(value),
      uppercase: /[A-Z]/.test(value),
      number: /\d/.test(value),
      specialChar: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value),
    });
  };

  function isValidPassword(password) {
    if (!(password.length >= 8 && password.length <= 32)) {
      return false;
    }
    if (password.indexOf(" ") !== -1) {
      return false;
    }
    let count = 0;
    for (let i = 0; i <= 9; i++) {
      if (password.indexOf(i) !== -1) {
        count = 1;
      }
    }
    if (count === 0) {
      return false;
    }
    if (!/[!@#$%^&*()_+=[\]{};':"|,.<>/?]/.test(password)) {
      return false;
    }
    count = 0;
    for (let i = 65; i <= 90; i++) {
      if (password.indexOf(String.fromCharCode(i)) !== -1) {
        count = 1;
      }
    }
    if (count === 0) {
      return false;
    }
    count = 0;
    for (let i = 97; i <= 122; i++) {
      if (password.indexOf(String.fromCharCode(i)) !== -1) {
        count = 1;
      }
    }
    if (count === 0) {
      return false;
    }
    return true;
  }
  const handleClick = () => {
    localData("save", "chatting", !showChat);
    setShowChat(!showChat);
  };
  const handleRecover = async (event) => {
    event.preventDefault();
    if (isValidPassword(password)) {
      setLoading(true);
      const data = {
        reset: token,
        password: password,
      };

      const responseData = await runData(data, "/query/recover/");
      setLoading(false);
      if (responseData.response) {
        switch (responseData.response.data.code) {
          case 200:
            const sessionFull = {
              name: responseData.response.data.name,
              role: responseData.response.data.role,
              token: responseData.response.data.session,
            };
            localData("save", "session", sessionFull);
            const time = new Date().getTime();
            localData("save", "expiry", time);
            setMessage("You have updated your password successfully!");
            setReporting(true);
            localData("save", "messages", {
              user: {
                name: responseData.response.data.name,
                email: responseData.response.data.email,
                phone: responseData.response.data.phone,
              },
              thread: [
                {
                  sender: "bot",
                  text: "Hi there! I can assist you with our service information.",
                  date: new Date(),
                },
              ],
            });
            break;

          default:
            setMessage(responseData.response.data.message);
            setReporting(true);
        }
      } else if (responseData.error) {
        setLoading(false);
        setMessage(responseData.error.message);
        setReporting(true);
      } else {
        setLoading(false);
        setMessage("An error occured while processing your request.");
        setReporting(true);
      }
    } else {
      setMessage("Invalid password input, please try again!");
      setReporting(true);
    }
  };

  const isAllValid = Object.values(isValid).every((val) => val === true);

  const customStyles = {
    overlay: {
      backgroundColor: "#ffffffeb",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <div
      className="home"
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        wordWrap: "break-word",
        backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.77) 100%, rgb(255, 255, 255) 100%), url(${bgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Helmet>
        <title>{title} :: Set New Password- Marmasco</title>
      </Helmet>
      <div className="card">
        <div
          className="card-header d-flex justify-content-between align-items-center marmasco-padding"
          style={{
            background: "#eee",
            position: "sticky",
            top: 0,
            zIndex: 9,
          }}
        >
          <h4>Setting Password</h4>
          <FontAwesomeIcon icon={faUserCog} size="2x" color="grey" />
        </div>
        <div className="card-body marmasco-padding-large">
          <Form onSubmit={handleRecover} style={{ marginTop: "50px" }}>
            <Form.Group controlId="formBasicEmail">
              <FloatingLabel
                controlId="password"
                label="New Password"
                className="mb-3"
              >
                <Form.Control
                  type="password"
                  placeholder="password"
                  value={password}
                  onChange={handlePasswordChange}
                  className="form-control form-line"
                  required
                />
              </FloatingLabel>
            </Form.Group>
            <ul>
              <li style={{ color: isValid.length ? "green" : "red" }}>
                At least 8 characters
              </li>
              <li style={{ color: isValid.lowercase ? "green" : "red" }}>
                At least one lowercase letter
              </li>
              <li style={{ color: isValid.uppercase ? "green" : "red" }}>
                At least one uppercase letter
              </li>
              <li style={{ color: isValid.number ? "green" : "red" }}>
                At least one number
              </li>
              <li style={{ color: isValid.specialChar ? "green" : "red" }}>
                At least one special character
              </li>
            </ul>
            <Button
              variant="success"
              type="submit"
              style={{ width: "100%" }}
              disabled={!isAllValid}
            >
              Save Password
            </Button>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Link to="/login/" className="btn btn-link">
                Remembered Password?
              </Link>
              <span className="btn btn-link" onClick={handleClick}>
                Live Chat
              </span>
            </div>
          </Form>
           <div className="or-separator">
            <div className="line"></div>
            <span>OR</span>
            <div className="line"></div>
          </div>
          <Button variant="danger" className="mt-3" style={{ width: "100%" }}>
            <FontAwesomeIcon icon={faGoogle} /> Login with Google
          </Button>
          <Button variant="primary" className="mt-3" style={{ width: "100%" }}>
            <FontAwesomeIcon icon={faFacebook} /> Login with Facebook
          </Button>
        </div>
      </div>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          className="bg-secondary text-white"
          style={{
            maxWidth: "100%",
            width: "500px",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            RESPONSE{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="marmasco-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />

          <p className="marmasco-center">{message}</p>

          <br />
          <button
            className="marmasco-btn marmasco-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={loading}
        onRequestClose={() => setLoading(false)}
        style={customStyles}
      >
        <FontAwesomeIcon icon={faSpinner} color="#aaa" size="7x" spin />
      </Modal>
    </div>
  );
}

export default Recover;
