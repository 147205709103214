import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Top from "../includes/topping";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { runData } from "../../jsx/backend";
import Loader from "../includes/loader";
import MarketingImg from "../../img/bg.jpg";

Modal.setAppElement("#root");

const Privacy = () => {
  const [title, setTitle] = useState("Loading");
  const [description, setDescription] = useState("Loading");
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);
  const [loading, setLoading] = useState(true);

  const pageTitle = async () => {
    const data = {
      page: "privacy",
    };
    const responseData = await runData(data, `/page/`);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTitle(responseData.response.data.title);
          setDescription(responseData.response.data.description);
          setLoading(false);
          break;

        default:
          setMessage(responseData.response.data.message);
          setTitle(responseData.response.data.message);
          setLoading(false);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setTitle(responseData.error.message);
      setLoading(false);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setTitle("An error occured while processing your request.");
      setLoading(false);
      setReporting(true);
    }
  };

  useEffect(() => {
    pageTitle();
  }, []);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <div
      style={{
        minHeight: "75VH",
      }}
    >
      <Helmet>
        <title>{title} - Marmasco</title>
      </Helmet>
      {loading ? (
        <div
          className="marmasco-white"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="marmasco-animate-top">
          <div
            style={{
              backgroundImage: `url(${MarketingImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.85)",
                minHeight: "100%",
                width: "100%",
              }}
            >
              <div
                className="marmasco-container marmasco-center marmasco-text-white"
                style={{
                  marginTop: "-50px",
                  paddingTop: "90px",
                  paddingBottom: "90px",
                }}
              >
                <h1 style={{ textTransform: "uppercase" }}>{title}</h1>
                <p>{description}</p>
              </div>
            </div>
          </div>

          <div
            className="marmasco-container marmasco-content"
            style={{
              paddingTop: "120px",
              paddingBottom: "100px",
              height: "100%",
            }}
          >
            <h4>1. Introduction</h4>
            <p>
              Marmasco Technologies Private Limited ("we", "us", "our") respects
              your privacy and is committed to protecting your personal data.
              This Privacy Policy will inform you as to how we look after your
              personal data when you visit our website (regardless of where you
              visit it from) and tell you about your privacy rights and how the
              law protects you.
            </p>

            <h4>2. Personal Data We Collect</h4>
            <p>
              We may collect, use, store and transfer different kinds of
              personal data about you which we have grouped together as follows:
            </p>
            <ul style={{ liststyletype: "disc" }}>
              <li>
                <p>
                  Identity Data includes first name, last name, username or
                  similar identifier, marital status, title, date of birth and
                  gender.
                </p>
              </li>
              <li>
                <p>
                  Contact Data includes billing address, delivery address, email
                  address and telephone numbers.
                </p>
              </li>
              <li>
                <p>
                  Financial Data includes bank account and payment card details.
                </p>
              </li>
              <li>
                <p>
                  Transaction Data includes details about payments to and from
                  you and other details of products and services you have
                  purchased from us.
                </p>
              </li>
            </ul>
            <h4>3. How We Use Your Personal Data</h4>
            <p>
              We will only use your personal data when the law allows us to.
              Most commonly, we will use your personal data in the following
              circumstances:
            </p>
            <ul style={{ liststyletype: "disc" }}>
              <li>
                <p>
                  Where we need to perform the contract we are about to enter
                  into or have entered into with you.
                </p>
              </li>
              <li>
                <p>
                  Where it is necessary for our legitimate interests (or those
                  of a third party) and your interests and fundamental rights do
                  not override those interests.
                </p>
              </li>
              <li>
                <p>Where we need to comply with a legal obligation.</p>
              </li>
            </ul>
            <h4>4. Data Security</h4>
            <p>
              We have put in place appropriate security measures to prevent your
              personal data from being accidentally lost, used or accessed in an
              unauthorised way, altered or disclosed. In addition, we limit
              access to your personal data to those employees, agents,
              contractors and other third parties who have a business need to
              know. They will only process your personal data on our
              instructions and they are subject to a duty of confidentiality.
            </p>

            <h4>5. Data Retention</h4>
            <p>
              We will only retain your personal data for as long as necessary to
              fulfil the purposes we collected it for, including for the
              purposes of satisfying any legal, accounting, or reporting
              requirements.
            </p>

            <h4>6. Your Legal Rights</h4>
            <p>
              Under certain circumstances, you have rights under data protection
              laws in relation to your personal data. These include the right
              to:
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>
                <p>Request access to your personal data.</p>
              </li>
              <li>
                <p>Request correction of your personal data.</p>
              </li>
              <li>
                <p>Request erasure of your personal data.</p>
              </li>
              <li>
                <p>Object to processing of your personal data.</p>
              </li>
              <li>
                <p>Request restriction of processing your personal data.</p>
              </li>
              <li>
                <p>Request transfer of your personal data.</p>
              </li>
              <li>
                <p>Right to withdraw consent.</p>
              </li>
            </ul>
            <h4>7. Law and Jurisdiction</h4>
            <p>
              This Privacy Policy will be governed by and construed in
              accordance with Zimbabwean law, and any disputes relating to this
              Privacy Policy will be subject to the exclusive jurisdiction of
              the courts of Zimbabwe.
            </p>
          </div>
          <div
            className="marmasco-container"
            style={{
              paddingTop: "50px",
              paddingBottom: "80px",
            }}
          ></div>

          <Modal
            isOpen={report}
            onRequestClose={() => setReporting(false)}
            style={customStyles}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#f8f9fa",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              }}
            >
              <h4>
                Message{" "}
                <span
                  onClick={() => setReporting(false)}
                  title="Close"
                  className="marmasco-right"
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faTimes} color="red" />
                </span>
              </h4>

              <hr />

              <p className="marmasco-center">{message}</p>

              <br />
              <button
                className="marmasco-btn marmasco-red"
                onClick={() => setReporting(false)}
                style={{ borderRadius: "30px", width: "100%" }}
              >
                CLOSE
              </button>
            </div>
          </Modal>

          <Top />
        </div>
      )}
    </div>
  );
};

export default Privacy;
