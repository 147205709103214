import React, { useState, useEffect } from "react";
import {
  Spinner,
  Alert,
  Container,
  Card,
  Row,
  Col,
  Button,
  Form,
  CardFooter,
  CardHeader,
  FloatingLabel,
} from "react-bootstrap";
import { cartData, runData } from "../../jsx/backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faGlobe,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { Link } from "react-router-dom";

Modal.setAppElement("#root");

const SearchDomain = () => {
  const [domain, setDomain] = useState("");
  const [price, setPrice] = useState(0.0);
  const [quantity, setQuantity] = useState(1);
  const [currency, setCurrency] = useState("USD");
  const [description, setDescription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [adding, setAdding] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [isInCart, setIsInCart] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (selectedItem) {
      let itemExists = cartData("check", selectedItem);
      setIsInCart(itemExists);
    }
  }, [selectedItem]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);
    setResult(null);
    setCurrency("USD");
    setPrice(0.0);
    setDescription(null);
    setSelectedItem(null);
    setQuantity(1);

    const data = {
      domain: domain,
    };

    try {
      const responseData = await runData(data, `/check/domain/`);
      setIsLoading(false);

      if (responseData.response) {
        switch (responseData.response.data.code) {
          case 200:
            setResult(responseData.response.data.status);
            setPrice(responseData.response.data.price);
            setCurrency(responseData.response.data.currency);
            setDescription(responseData.response.data.description);
            setSelectedItem(
              `${responseData.response.data.reference}_${domain}`
            );
            break;
          default:
            setError(responseData.response.data.message);
        }
      } else if (responseData.error) {
        setError(responseData.error.message);
      }
    } catch (error) {
      setError("There was an error while processing request.");
      setIsLoading(false);
    }
  };

  function handleClick(reference) {
    setError(null);
    setResult(null);
    let itemExists = cartData("check", reference);
    if (itemExists) {
      cartData("remove", reference);
      setIsInCart(false);
    } else {
      let item = {
        product: domain,
        type: "Domain",
        quantity: quantity,
        price: price,
      };
      cartData("save", reference, item);
      setIsInCart(true);
    }
  }

  const customStyles = {
    overlay: {
      backgroundColor: "#ffffffeb",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <>
      <Container fluid>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <form
              className="card card-sm"
              onSubmit={handleSubmit}
              style={{ marginBottom: "15px", padding: "20px" }}
            >
              <div className="card-body row no-gutters align-items-center">
                <div
                  className="col-auto"
                  style={{ padding: "10px", fontSize: "20px" }}
                >
                  <span>www.</span>
                </div>
                <div className="col" style={{ padding: "10px" }}>
                  <input
                    className="form-control form-control-lg form-control-borderless no-outline"
                    type="search"
                    value={domain}
                    onChange={(e) => {
                      setDomain(e.target.value);
                      setResult(null);
                      setError(null);
                    }}
                    placeholder="Enter domain name"
                  />
                </div>
                <div className="col-auto" style={{ padding: "10px" }}>
                  <button
                    className="btn btn-lg btn-success marmasco-color"
                    type="submit"
                  >
                    {isLoading ? (
                      <span>
                        Wait <Spinner animation="border" size="sm" />
                      </span>
                    ) : (
                      <span>
                        <FontAwesomeIcon icon={faGlobe} /> Search
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div
            className="col-12 col-md-10 col-lg-8"
            style={{ marginTop: "15px" }}
          >
            {result && (
              <Alert variant={result === "available" ? "success" : "warning"}>
                <div className="d-flex justify-content-between">
                  <h4>
                    {result === "available"
                      ? `Congratulations! ${domain} is available for registration. Register it with us for ${currency} ${price}.`
                      : `Oops! ${domain} is already taken. Transfer it to us for ${currency} ${price}.`}
                  </h4>
                  <h4
                    onClick={() => setAdding(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <FontAwesomeIcon icon={faCartPlus} /> ORDER
                  </h4>
                </div>
              </Alert>
            )}
            {error && (
              <Alert
                variant="danger"
                onClose={() => setError(null)}
                dismissible
              >
                {error}
              </Alert>
            )}
          </div>
        </div>
      </Container>
      <Modal
        isOpen={adding}
        onRequestClose={() => setAdding(false)}
        style={customStyles}
      >
        <Container
          className="d-flex justify-content-center align-items-center"
          style={{ width: "800px", maxWidth: "100%" }}
        >
          <Card className="w-100 p-4 shadow-sm rounded">
            <CardHeader
              className="marmasco-white"
              style={{ marginBottom: "15px" }}
            >
              <Row>
                <Col>
                  <h4>Order Domain ({domain})</h4>
                </Col>
                <Col className="text-end">
                  <FontAwesomeIcon
                    icon={faTimes}
                    color="red"
                    onClick={() => setAdding(false)}
                    title="Close"
                    style={{ cursor: "pointer" }}
                  />
                </Col>
              </Row>
            </CardHeader>
            <Card.Body className="p-4">
              {isInCart ? (
                <div className="text-center my-4">
                  <h1>You're Almost Ready!</h1>
                  <Row className="mt-4">
                    <Col
                      xs={12}
                      md={4}
                      className="mb-3 mb-md-0 d-flex justify-content-center"
                    >
                      <Button
                        variant="primary"
                        style={{ height: "50px", width: "80%" }}
                        onClick={() => {
                          setAdding(false);
                          setDomain("");
                          setError(null);
                          setResult(null);
                        }}
                      >
                        Continue Shopping
                      </Button>
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      className="mb-3 mb-md-0 d-flex justify-content-center"
                    >
                      <Link to="/hosting/" style={{ width: "80%" }}>
                        <Button
                          variant="secondary"
                          style={{ height: "50px", width: "100%" }}
                        >
                          Add Hosting
                        </Button>
                      </Link>
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      className="d-flex justify-content-center"
                    >
                      <Link to="/checkout/" style={{ width: "80%" }}>
                        <Button
                          variant="success"
                          style={{ height: "50px", width: "100%" }}
                          className="marmasco-color"
                        >
                          Finish Order
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </div>
              ) : (
                <Card.Text
                  className="my-4"
                  dangerouslySetInnerHTML={{ __html: description }}
                ></Card.Text>
              )}
            </Card.Body>
            <CardFooter className="marmasco-white">
              <Row className="align-items-center" style={{ marginTop: "20px" }}>
                <Col xs={4}>
                  <Form.Group className="mb-3">
                    <FloatingLabel
                      controlId="quantity"
                      label="Increase Years"
                      className="mb-3"
                    >
                      <Form.Control
                        placeholder="years"
                        type="number"
                        min={1}
                        max={10}
                        step={1}
                        value={quantity}
                        onChange={(e) => {
                          setQuantity(e.target.value);
                        }}
                        className="form-control form-line"
                        required
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={4}>
                  <Button variant="transparent">
                    TOTAL: {currency} {(price * quantity).toFixed(2)}
                  </Button>
                </Col>
                <Col xs={4} className="text-end">
                  <Button
                    variant={isInCart ? "danger" : "success"}
                    className={isInCart ? "" : "marmasco-color"}
                    onClick={() => handleClick(selectedItem)}
                  >
                    {isInCart ? "REMOVE ITEM" : "PLACE ORDER"}
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Container>
      </Modal>
    </>
  );
};

export default SearchDomain;
