import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { cartData, localData } from "./backend";
import Header from "../web/sections/header";
import Home from "../web/pages/home";
import { useCheckActivity } from "./hooks/activity";
import CookieBanner from "../web/pages/public/cookies";
import FourOFour from "../web/pages/public/404";
import Footer from "../web/sections/footer";
import Domains from "../web/pages/services/domains";
import Hosting from "../web/pages/services/hosting";
import Marketing from "../web/pages/services/marketing";
import About from "../web/pages/about";
import Contact from "../web/pages/contact";
import Resource from "../web/pages/resources";
import Terms from "../web/pages/terms";
import Privacy from "../web/pages/privacy";
import Refund from "../web/pages/refund";
import ServerError from "../web/pages/public/serror";
import Systems from "../web/pages/services/systems";
import { useMessageCount } from "./hooks/messages";
import icoImg from "../img/favicon.ico";
import Chat from "../web/sections/chat";
import Login from "../web/pages/login";
import Reset from "../web/pages/reset";
import Recover from "../web/pages/recover";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isBranded, setIsBranded] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isActive = useCheckActivity(localData("get", "branding").timeout || 5);
  const [isSaving, setIsSaving] = useState(true);

  const messageCount = useMessageCount();

  useEffect(() => {
    if (messageCount > 0) {
      const canvas = document.createElement("canvas");
      canvas.height = 64;
      canvas.width = 64;
      const ctx = canvas.getContext("2d");

      const img = new Image();
      img.src = icoImg;
      img.onload = () => {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        ctx.beginPath();
        ctx.arc(
          canvas.width / 2,
          canvas.height / 2,
          (canvas.width / 2) * 0.7,
          0,
          Math.PI * 2,
          false
        );
        ctx.fillStyle = "#009688";
        ctx.fill();
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = "bold 36px Arial";
        ctx.fillStyle = "white";
        ctx.fillText(
          messageCount > 9 ? "9+" : messageCount.toString(),
          canvas.width / 2,
          canvas.height / 2
        );

        const newFavicon = canvas.toDataURL();
        document.querySelector("link[rel='icon']").href = newFavicon;
      };
    } else if (messageCount === 0) {
      document.querySelector("link[rel='icon']").href = icoImg;
    }
  }, [messageCount]);

  const validateApplication = () => {
    const authentication = localData("get", "session");
    const customisation = localData("get", "branding");
    if (
      !(Object.keys(authentication).length > 0) ||
      !(Object.keys(customisation).length > 0)
    ) {
      setIsAuthenticated(false);
      setIsBranded(false);
      setIsSaving(localData("get", "cookies"));
      setIsLoading(false);
    } else {
      setIsAuthenticated(true);
      setIsBranded(true);
      setIsSaving(localData("get", "cookies"));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      validateApplication();
    }, 500);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Router>
      {localData("get", "error") ? (
        <ServerError />
      ) : (
        <>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />

            <Route exact path="/hosting/" element={<Hosting />} />

            <Route exact path="/domains/" element={<Domains />} />

            <Route exact path="/marketing/" element={<Marketing />} />

            <Route exact path="/resources/" element={<Resource />} />

            <Route exact path="/systems/" element={<Systems />} />

            <Route exact path="/about/" element={<About />} />

            <Route exact path="/contact/" element={<Contact />} />

            <Route exact path="/terms/" element={<Terms />} />

            <Route exact path="/privacy/" element={<Privacy />} />

            <Route exact path="/refund/" element={<Refund />} />

            <Route exact path="/onboarding/" element={<Home />} />

            <Route exact path="/account/" element={<Home />} />

            <Route exact path="/login/" element={<Login />} />

            <Route exact path="/reset/" element={<Reset />} />

            <Route exact path="/reset/:token/" element={<Recover />} />

            <Route exact path="*" element={<FourOFour />} />
          </Routes>
          {!isSaving && <CookieBanner />}
          <Chat />
          <Footer />
        </>
      )}
    </Router>
  );
};

export default App;
