import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { localData, runData } from "../../jsx/backend";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faTimes,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import getIcon from "../includes/icons";
import { Button, Dropdown, FloatingLabel, Form } from "react-bootstrap";
import bgImg from "../../img/bg.jpg";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";

Modal.setAppElement("#root");

function Reset() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [report, setReporting] = useState(false);
  const [showChat, setShowChat] = useState(localData("get", "chatting"));
  const [title, setTitle] = useState("Loading");

  const pageTitle = async () => {
    const data = {
      page: "reset",
    };
    const responseData = await runData(data, `/page/`);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTitle(responseData.response.data.title);
          setLoading(false);
          break;

        default:
          setMessage(responseData.response.data.message);
          setTitle(responseData.response.data.message);
          setLoading(false);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setTitle(responseData.error.message);
      setLoading(false);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setTitle("An error occured while processing your request.");
      setLoading(false);
      setReporting(true);
    }
  };

  useEffect(() => {
    pageTitle();
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleReset = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = {
      email: email,
    };

    const responseData = await runData(data, "/query/reset/");
    setLoading(false);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setMessage(responseData.response.data.message);
          setReporting(true);
          break;

        default:
          setLoading(false);
          setMessage(
            `${responseData.response.data.message}: Make sure you have entered your actual email address that is associated with your account.`
          );
          setReporting(true);
      }
    } else if (responseData.error) {
      setLoading(false);
      setMessage(responseData.error.message);
      setReporting(true);
    } else {
      setLoading(false);
      setMessage("An error occured while processing your request.");
      setReporting(true);
    }
  };
  const handleClick = () => {
    localData("save", "chatting", !showChat);
    setShowChat(!showChat);
  };
  const customStyles = {
    overlay: {
      backgroundColor: "#ffffffeb",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <div
      className="home"
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        wordWrap: "break-word",
        backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.77) 100%, rgb(255, 255, 255) 100%), url(${bgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Helmet>
        <title>{title} - Marmasco</title>
      </Helmet>
      <div className="card">
        <div
          className="card-header d-flex justify-content-between align-items-center marmasco-padding"
          style={{
            background: "#eee",
            position: "sticky",
            top: 0,
            zIndex: 9,
          }}
        >
          <h4>Reset Password</h4>
          <FontAwesomeIcon icon={faUserCog} size="2x" color="grey" />
        </div>
        <div className="card-body marmasco-padding-large">
          <Form onSubmit={handleReset}>
            <Form.Group controlId="formBasicEmail">
              <FloatingLabel
                controlId="email"
                label="Email Address"
                className="mb-3"
              >
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  value={email}
                  onChange={handleEmailChange}
                  className="form-control form-line"
                  required
                />
              </FloatingLabel>
            </Form.Group>

            <Button
              variant="light"
              className="marmasco-color"
              type="submit"
              style={{ width: "100%" }}
            >
              Start Resetting
            </Button>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Link to="/login/" className="btn btn-link">
                Remembered Password?
              </Link>
              <span className="btn btn-link" onClick={handleClick}>
                Live Chat
              </span>
            </div>
          </Form>
          <div className="or-separator">
            <div className="line"></div>
            <span>OR</span>
            <div className="line"></div>
          </div>
          <Button variant="danger" className="mt-3" style={{ width: "100%" }}>
            <FontAwesomeIcon icon={faGoogle} /> Login with Google
          </Button>
          <Button variant="primary" className="mt-3" style={{ width: "100%" }}>
            <FontAwesomeIcon icon={faFacebook} /> Login with Facebook
          </Button>
        </div>
      </div>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          className="bg-secondary text-white"
          style={{
            maxWidth: "100%",
            width: "500px",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            Response{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="marmasco-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />

          <p className="marmasco-center">{message}</p>

          <br />
          <button
            className="marmasco-btn marmasco-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={loading}
        onRequestClose={() => setLoading(false)}
        style={customStyles}
      >
        <FontAwesomeIcon icon={faSpinner} color="#aaa" size="7x" spin />
      </Modal>
    </div>
  );
}

export default Reset;
