import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Top from "../includes/topping";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { runData } from "../../jsx/backend";
import Loader from "../includes/loader";
import MarketingImg from "../../img/bg.jpg";

Modal.setAppElement("#root");

const Refund = () => {
  const [title, setTitle] = useState("Loading");
  const [description, setDescription] = useState("Loading");
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);
  const [loading, setLoading] = useState(true);

  const pageTitle = async () => {
    const data = {
      page: "refund",
    };
    const responseData = await runData(data, `/page/`);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTitle(responseData.response.data.title);
          setDescription(responseData.response.data.description);
          setLoading(false);
          break;

        default:
          setMessage(responseData.response.data.message);
          setTitle(responseData.response.data.message);
          setLoading(false);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setTitle(responseData.error.message);
      setLoading(false);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setTitle("An error occured while processing your request.");
      setLoading(false);
      setReporting(true);
    }
  };

  useEffect(() => {
    pageTitle();
  }, []);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <div
      style={{
        minHeight: "75VH",
      }}
    >
      <Helmet>
        <title>{title} - Marmasco</title>
      </Helmet>
      {loading ? (
        <div
          className="marmasco-white"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="marmasco-animate-top">
          <div
            style={{
              backgroundImage: `url(${MarketingImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.85)",
                minHeight: "100%",
                width: "100%",
              }}
            >
              <div
                className="marmasco-container marmasco-center marmasco-text-white"
                style={{
                  marginTop: "-50px",
                  paddingTop: "90px",
                  paddingBottom: "90px",
                }}
              >
                <h1 style={{ textTransform: "uppercase" }}>{title}</h1>
                <p>{description}</p>
              </div>
            </div>
          </div>

          <div
            className="marmasco-container marmasco-content"
            style={{
              paddingTop: "120px",
              paddingBottom: "100px",
              height: "100%",
            }}
          >
            <h4>1. Introduction</h4>
            <p>
              Marmasco Technologies Private Limited ("we", "us", "our") is
              committed to ensuring satisfaction for all our customers. This
              Refund Policy ("Policy") applies to all purchases made through our
              website, mobile application, or in-person. By making a purchase,
              you agree to this Policy.
            </p>

            <h4>2. General Terms</h4>
            <p>
              If you are not fully satisfied with your purchase, you may request
              a full refund on the purchase price within 30 days. We will not,
              in any circumstance, cover the return shipping costs.
            </p>

            <h4>3. Products and Services</h4>
            <p>
              For products, the product must be returned, unused or used, in the
              condition you received it and with the original box and/or
              packaging, including manufacturer tags where applicable.
            </p>
            <p>
              For services, your refund will be prorated based on the percentage
              of the service already completed at the time of your refund
              request, or the time that has elapsed since the purchase if the
              service has not yet begun.
            </p>

            <h4>4. Refund Requests</h4>
            <p>
              To request a refund, please contact us via our website or email at
              support@marmasco.com. In your message, please include your order
              number, the items or services you would like to return, and the
              reason for the return. We will respond as quickly as possible with
              instructions on how to proceed.
            </p>

            <h4>5. Refund Process</h4>
            <p>
              Once we receive your returned item, we will inspect it and process
              your refund. The money will be refunded to the original payment
              method you used during the purchase. For credit card payments, it
              may take 5 to 10 business days for a refund to show up on your
              credit card statement.
            </p>

            <h4>6. Exceptions</h4>
            <p>
              Some items are non-refundable and non-exchangeable. These include
              custom products, digital products, and services that have been
              fully completed.
            </p>

            <h4>7. Changes to This Policy</h4>
            <p>
              We reserve the right to modify this Policy at any time, so please
              review it frequently. Changes and clarifications will take effect
              immediately upon their posting on the website.
            </p>

            <h4>8. Contact Us</h4>
            <p>
              If you have any questions about this Policy or our products,
              please contact us at support@marmasco.com.
            </p>

            <h4>9. Law and Jurisdiction</h4>
            <p>
              This Refund Policy will be governed by and construed in accordance
              with Zimbabwean law, and any disputes relating to this Refund
              Policy will be subject to the exclusive jurisdiction of the courts
              of Zimbabwe.
            </p>
          </div>
          <div
            className="marmasco-container"
            style={{
              paddingTop: "50px",
              paddingBottom: "80px",
            }}
          ></div>

          <Modal
            isOpen={report}
            onRequestClose={() => setReporting(false)}
            style={customStyles}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#f8f9fa",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              }}
            >
              <h4>
                Message{" "}
                <span
                  onClick={() => setReporting(false)}
                  title="Close"
                  className="marmasco-right"
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faTimes} color="red" />
                </span>
              </h4>

              <hr />

              <p className="marmasco-center">{message}</p>

              <br />
              <button
                className="marmasco-btn marmasco-red"
                onClick={() => setReporting(false)}
                style={{ borderRadius: "30px", width: "100%" }}
              >
                CLOSE
              </button>
            </div>
          </Modal>

          <Top />
        </div>
      )}
    </div>
  );
};

export default Refund;
