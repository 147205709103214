import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Top from "../../includes/topping";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalculator,
  faChartLine,
  faCode,
  faCog,
  faCommentDots,
  faLightbulb,
  faMoneyBills,
  faPalette,
  faServer,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { runData } from "../../../jsx/backend";
import Loader from "../../includes/loader";
import MarketingImg from "../../../img/marketing.png";
import marketingImage from "../../../img/digital-shout.png";
import BgImg from "../../../img/bg.png";
import ParticlesBackground from "../../includes/background";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { useMessageCount } from "../../../jsx/hooks/messages";

Modal.setAppElement("#root");

const Marketing = () => {
  const [title, setTitle] = useState("Loading");
  const [description, setDescription] = useState("Loading");
  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);
  const [hostingType, setHostingType] = useState("");
  const [managementType, setManagementType] = useState("");
  const [billingPeriod, setBillingPeriod] = useState("");
  const [loading, setLoading] = useState(true);
  const messageCount = useMessageCount();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here
  };

  const pageTitle = async () => {
    const data = {
      page: "marketing",
    };
    const responseData = await runData(data, `/page/`);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTitle(responseData.response.data.title);
          setDescription(responseData.response.data.description);
          setLoading(false);
          break;

        default:
          setMessage(responseData.response.data.message);
          setTitle(responseData.response.data.message);
          setLoading(false);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setTitle(responseData.error.message);
      setLoading(false);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setTitle("An error occured while processing your request.");
      setLoading(false);
      setReporting(true);
    }
  };

  useEffect(() => {
    pageTitle();
  }, []);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <div
      style={{
        minHeight: "75VH",
        backgroundImage: `url(${BgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Helmet>
        <title>{title} - Marmasco</title>
      </Helmet>
      <ParticlesBackground />
      {loading ? (
        <div
          className="marmasco-white"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="marmasco-animate-top">
          <div
            style={{
              backgroundImage: `url(${MarketingImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.85)",
                minHeight: "100%",
                width: "100%",
              }}
            >
              <div
                className="container-fluid marmasco-center marmasco-text-white"
                style={{
                  marginTop: "-50px",
                  paddingTop: "90px",
                  paddingBottom: "90px",
                }}
              >
                <h1 style={{ textTransform: "uppercase" }}>{title}</h1>
                <p>{description}</p>
              </div>
            </div>
          </div>

          <div
            className="container-fluid marmasco-padding-large"
            style={{
              marginTop: "150px",
            }}
          >
            <form
              onSubmit={handleSubmit}
              className="flex-form"
              style={{
                maxWidth: "100%",
                margin: "0 auto",
                border: "1px solid #ccc",
                borderRadius: "5px",
                overflow: "hidden",
                padding: "30px",
                background: "#eee",
              }}
            >
              <div
                className="form-item"
                style={{
                  marginRight: "20px",
                  background: "#ddd",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <label
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    position: "relative",
                  }}
                >
                  Digital Service
                  <FontAwesomeIcon
                    icon={faServer}
                    style={{
                      position: "absolute",
                      right: "0",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  />
                </label>
                <select
                  value={hostingType}
                  onChange={(e) => setHostingType(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "none",
                    background: "transparent",
                    cursor: "pointer",
                  }}
                >
                  <option value="">Select service...</option>
                  <option value="shared">Web Development</option>
                  <option value="vps">Web Application</option>
                  <option value="vps">Mobile Application</option>
                  <option value="vps">Social Media Makerting</option>
                  <option value="vps">SEO & Content Writing</option>
                </select>
              </div>
              <div
                className="form-item"
                style={{
                  marginRight: "20px",
                  background: "#ddd",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <label
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    position: "relative",
                  }}
                >
                  Contract Duration
                  <FontAwesomeIcon
                    icon={faCog}
                    style={{
                      position: "absolute",
                      right: "0",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  />
                </label>
                <select
                  value={managementType}
                  onChange={(e) => setManagementType(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "none",
                    background: "transparent",
                    cursor: "pointer",
                  }}
                >
                  <option value="">Select duration...</option>
                  <option value="close">One-Year Contract</option>
                  <option value="open">Open-Ended Contract</option>
                </select>
              </div>
              <div
                className="form-item"
                style={{
                  marginRight: "20px",
                  background: "#ddd",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <label
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    position: "relative",
                  }}
                >
                  Billing Cycle
                  <FontAwesomeIcon
                    icon={faMoneyBills}
                    style={{
                      position: "absolute",
                      right: "0",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  />
                </label>
                <select
                  value={billingPeriod}
                  onChange={(e) => setBillingPeriod(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "10px",
                    border: "none",
                    background: "transparent",
                    cursor: "pointer",
                  }}
                >
                  <option value="">Select cycle...</option>
                  <option value="monthly">Monthly</option>
                  <option value="quarterly">Quarterly</option>
                  <option value="annually">Annually</option>
                  <option value="once">Once-Off</option>
                </select>
              </div>
              <button
                className="form-item marmasco-center"
                type="submit"
                style={{
                  padding: "10px",
                  backgroundColor: "#009688",
                  color: "#fff",
                  border: "none",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faCalculator} /> CALCULATE PRICE
              </button>
            </form>
          </div>

          <div className="container-fluid" style={{ marginTop: "150px" }}>
            <div className="row marmasco-padding-large">
              <div className="col-md-6">
            
                <div className="marmasco-justify">
                  <p>
                    We specialise in a wide range of IT services, including web
                    development, digital marketing, and corporate branding. Our
                    team of highly skilled professionals is dedicated to helping
                    businesses establish a strong online presence and achieve
                    their digital goals. We leverage cutting-edge technologies
                    and innovative strategies to deliver solutions that not only
                    meet but exceed our clients' expectations.{" "}
                  </p>
                  <p>
                    At Marmasco Technologies, we understand that every business
                    is unique, and so are its digital needs. That's why we offer
                    customised web development services tailored to your
                    specific requirements. Whether you need a responsive
                    website, an e-commerce platform, or a complex web
                    application, we have the expertise and experience to
                    deliver. Our web solutions are designed to be user-friendly,
                    search engine optimised, and highly functional, ensuring a
                    seamless online experience for your customers.
                  </p>

                  <p>
                    In addition to web development, we offer comprehensive
                    digital marketing services to help your business reach its
                    target audience effectively. Our digital marketing
                    strategies are data-driven and results-oriented, focusing on
                    improving your online visibility, driving traffic to your
                    website, and converting visitors into customers.
                    Furthermore, our corporate branding services aim to create a
                    strong, consistent, and memorable brand identity that
                    resonates with your audience. From logo design to brand
                    strategy, we've got you covered. Partner with Marmasco
                    Technologies today and let us help you navigate the digital
                    landscape with confidence and success.
                  </p>
                </div>
              </div>

              <div className="col-md-6 grayscale">
                <img
                  src={marketingImage}
                  alt="Marketing"
                  className="centered-image"
                  style={{
                    height: "500px",
                    maxWidth: "100%",
                    objectFit: "contain",
                    borderRadius: "10px",
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className="container-fluid marmasco-container"
            style={{
              paddingBottom: "80px",
              marginTop: "150px",
            }}
          >
            <div className="flex-container">
              <div className="flex-item">
                <FontAwesomeIcon icon={faCode} size="5x" color="#009688" />
                <h2>Web Development</h2>
                <p>
                  Discover the power of a well-crafted website with our bespoke
                  web development services, designed to give your business a
                  competitive edge.
                </p>
              </div>
              <div className="flex-item">
                <FontAwesomeIcon icon={faChartLine} size="5x" color="#009688" />
                <h2>Digital Marketing</h2>
                <p>
                  Reach your target audience effectively with our data-driven
                  digital marketing strategies, aimed at boosting your online
                  visibility and customer engagement.
                </p>
              </div>
              <div className="flex-item">
                <FontAwesomeIcon icon={faPalette} size="5x" color="#009688" />
                <h2>Corporate Branding</h2>
                <p>
                  Build a strong, consistent brand identity with our
                  comprehensive corporate branding services, from logo design to
                  brand strategy.
                </p>
              </div>
              <div className="flex-item">
                <FontAwesomeIcon icon={faLightbulb} size="5x" color="#009688" />
                <h2>IT Consultancy</h2>
                <p>
                  Benefit from our expert IT consultancy services, offering you
                  innovative solutions and strategic guidance to navigate the
                  digital landscape with confidence.
                </p>
              </div>
            </div>
          </div>

          <Modal isOpen={running} style={customStyles}>
            <div
              className="marmasco-center"
              style={{
                width: "100%",
                backgroundColor: "#f8f9fa",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              }}
            >
              <h4 style={{ color: "#343a40" }}>Please wait...</h4>
              <br />
              <Loader />
            </div>
          </Modal>

          <Modal
            isOpen={report}
            onRequestClose={() => setReporting(false)}
            style={customStyles}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#f8f9fa",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              }}
            >
              <h4>
                Message{" "}
                <span
                  onClick={() => setReporting(false)}
                  title="Close"
                  className="marmasco-right"
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faTimes} color="red" />
                </span>
              </h4>

              <hr />

              <p className="marmasco-center">{message}</p>

              <br />
              <button
                className="marmasco-btn marmasco-red"
                onClick={() => setReporting(false)}
                style={{ borderRadius: "30px", width: "100%" }}
              >
                CLOSE
              </button>
            </div>
          </Modal>

          <Top />
        </div>
      )}
    </div>
  );
};

export default Marketing;
