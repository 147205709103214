import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Top from "../includes/topping";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCommentDots,
  faEyeLowVision,
  faHandshake,
  faTimes,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { runData } from "../../jsx/backend";
import Loader from "../includes/loader";
import MarketingImg from "../../img/bg.jpg";
import AboutImg from "../../img/maregedze.png";
import BgImg from "../../img/bg.png";
import ParticlesBackground from "../includes/background";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { useMessageCount } from "../../jsx/hooks/messages";

Modal.setAppElement("#root");

const About = () => {
  const [title, setTitle] = useState("Loading");
  const [description, setDescription] = useState("Loading");
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);
  const [loading, setLoading] = useState(true);
  const messageCount = useMessageCount();
  const [years, setYears] = useState(0);

  useEffect(() => {
    const calculateYears = () => {
      const startYear = 2015;
      const startMonth = 11;
      const startDate = new Date(startYear, startMonth);
      const currentDate = new Date();
      const years = currentDate.getFullYear() - startDate.getFullYear();
      const m = currentDate.getMonth() - startDate.getMonth();
      return m < 0 || (m === 0 && currentDate.getDate() < startDate.getDate())
        ? years - 1
        : years;
    };

    setYears(calculateYears());
  }, []);

  const pageTitle = async () => {
    const data = {
      page: "about",
    };
    const responseData = await runData(data, `/page/`);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTitle(responseData.response.data.title);
          setDescription(responseData.response.data.description);
          setLoading(false);
          break;

        default:
          setMessage(responseData.response.data.message);
          setTitle(responseData.response.data.message);
          setLoading(false);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setTitle(responseData.error.message);
      setLoading(false);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setTitle("An error occured while processing your request.");
      setLoading(false);
      setReporting(true);
    }
  };

  useEffect(() => {
    pageTitle();
  }, []);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <div
      style={{
        minHeight: "75VH",
        backgroundImage: `url(${BgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Helmet>
        <title>{title} - Marmasco</title>
      </Helmet>
      <ParticlesBackground />
      {loading ? (
        <div
          className="marmasco-white"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="marmasco-animate-top">
          <div
            style={{
              backgroundImage: `url(${MarketingImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.85)",
                minHeight: "100%",
                width: "100%",
              }}
            >
              <div
                className="marmasco-container marmasco-center marmasco-text-white"
                style={{
                  marginTop: "-50px",
                  paddingTop: "90px",
                  paddingBottom: "90px",
                }}
              >
                <h1 style={{ textTransform: "uppercase" }}>{title}</h1>
                <p>{description}</p>
              </div>
            </div>
          </div>

          <div
            className="container-fluid marmasco-padding-large"
            style={{ marginTop: "150px" }}
          >
            <div className="row">
              <div className="col-md-6">
                
                <div className="marmasco-justify">
                  <p>
                    Marmasco Technologies Private Limited, a leading provider of
                    comprehensive digital solutions, was established in 2015 by
                    Masimba Maregedze, a seasoned Software Engineer with a
                    passion for leveraging technology to drive business growth.
                    The company was formally registered on 3rd January 2019,
                    marking the beginning of its journey to becoming a trusted
                    partner for businesses seeking to navigate the digital
                    landscape. Marmasco Technologies is headquartered in
                    Chitungwiza, Zimbabwe, but its innovative solutions and
                    services have a global reach.
                  </p>

                  <p>
                    The company offers a wide range of services, including Web
                    Hosting, Domain Registration, and Digital Marketing. Our Web
                    Hosting services ensure that businesses have a reliable,
                    secure, and scalable online presence. We provide Domain
                    Registration services to help businesses establish their
                    unique identity on the internet. Our Digital Marketing
                    services are data-driven and designed to increase online
                    visibility, customer engagement, and return on investment.
                    At Marmasco Technologies, we are committed to delivering
                    bespoke solutions that align with our clients' business
                    objectives, leveraging our technical expertise and industry
                    insights to provide services that drive digital
                    transformation.
                  </p>
                  <h4 style={{ marginTop: "50px" }}>
                    Founder's Biography - Masimba Maregedze
                  </h4>
                  <p>
                    Masimba Maregedze is a seasoned Software Engineer and IT
                    Consultant with a wealth of experience spanning over {years}{" "}
                    years in the technology industry. Since embarking on his
                    professional journey in 2015, he has honed his skills in
                    mobile and web development, server administration, and IT
                    consultation. His expertise lies in designing and
                    implementing software solutions that drive efficiency and
                    productivity, with a particular focus on web design.
                    Masimba's comprehensive understanding of the IT landscape,
                    coupled with his ability to stay abreast of emerging trends,
                    has seen him successfully deliver on numerous projects,
                    earning him a reputation for excellence. His commitment to
                    providing top-tier IT consultation and web designing
                    services is underscored by his dedication to client
                    satisfaction, making him a reliable partner in navigating
                    the ever-evolving digital world. You can learn more on his
                    website: <a href="https://www.maregedze.com">www.maregedze.com</a>
                  </p>
                </div>
              </div>

              <div className="col-md-6 ">
                <img
                  src={AboutImg}
                  alt="Masimba Maregedze"
                  title="Masimba Maregedze"
                  className="centered-image"
                  style={{
                    height: "600px",
                    maxWidth: "100%",
                    objectFit: "contain",
                    borderRadius: "10px",
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className="container-fluid marmasco-container"
            style={{
              paddingTop: "50px",
              paddingBottom: "80px",
            }}
          >
            <div className="flex-container">
              <div className="flex-item">
                <FontAwesomeIcon icon={faBuilding} size="5x" color="#009688" />
                <h2>Our Company</h2>
                <p>
                  Founded in Zimbabwe, Marmasco has grown into a leading
                  provider of IT services, committed to delivering excellence
                  and innovation.
                </p>
              </div>
              <div className="flex-item">
                <FontAwesomeIcon icon={faUsers} size="5x" color="#009688" />
                <h2>Our Team</h2>
                <p>
                  Our team of dedicated professionals is the backbone of our
                  success. We are passionate about what we do and strive to
                  provide the best services to our clients.
                </p>
              </div>
              <div className="flex-item">
                <FontAwesomeIcon
                  icon={faEyeLowVision}
                  size="5x"
                  color="#009688"
                />
                <h2>Our Vision</h2>
                <p>
                  We aim to be a global leader in IT services, providing
                  innovative solutions that empower businesses and contribute to
                  their growth.
                </p>
              </div>
              <div className="flex-item">
                <FontAwesomeIcon icon={faHandshake} size="5x" color="#009688" />
                <h2>Our Commitment</h2>
                <p>
                  We are committed to delivering high-quality services that
                  exceed our clients' expectations. Your success is our success.
                </p>
              </div>
            </div>
          </div>

          <Modal
            isOpen={report}
            onRequestClose={() => setReporting(false)}
            style={customStyles}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#f8f9fa",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              }}
            >
              <h4>
                Message{" "}
                <span
                  onClick={() => setReporting(false)}
                  title="Close"
                  className="marmasco-right"
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faTimes} color="red" />
                </span>
              </h4>

              <hr />

              <p className="marmasco-center">{message}</p>

              <br />
              <button
                className="marmasco-btn marmasco-red"
                onClick={() => setReporting(false)}
                style={{ borderRadius: "30px", width: "100%" }}
              >
                CLOSE
              </button>
            </div>
          </Modal>

          <Top />
        </div>
      )}
    </div>
  );
};

export default About;
