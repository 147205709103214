import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Top from "../../includes/topping";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalculator,
  faCog,
  faCommentDots,
  faMoneyBills,
  faServer,
  faShieldAlt,
  faSync,
  faTimes,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { runData } from "../../../jsx/backend";
import Loader from "../../includes/loader";
import DomainsImg from "../../../img/hosting.jpg";
import HostingImg from "../../../img/cloud.png";
import BgImg from "../../../img/bg.png";
import ParticlesBackground from "../../includes/background";
import { Link } from "react-router-dom";
import { useMessageCount } from "../../../jsx/hooks/messages";
import { Badge } from "react-bootstrap";

Modal.setAppElement("#root");

const Hosting = () => {
  const [title, setTitle] = useState("Loading");
  const [description, setDescription] = useState("Loading");
  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);
  const [hostingType, setHostingType] = useState('');
  const [managementType, setManagementType] = useState('');
  const [billingPeriod, setBillingPeriod] = useState('');
  const [loading, setLoading] = useState(true);
  const messageCount = useMessageCount();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here
  };

  const pageTitle = async () => {
    const data = {
      page: "hosting",
    };
    const responseData = await runData(data, `/page/`);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTitle(responseData.response.data.title);
          setDescription(responseData.response.data.description);
          setLoading(false);
          break;

        default:
          setMessage(responseData.response.data.message);
          setTitle(responseData.response.data.message);
          setLoading(false);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setTitle(responseData.error.message);
      setLoading(false);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setTitle("An error occured while processing your request.");
      setLoading(false);
      setReporting(true);
    }
  };

  useEffect(() => {
    pageTitle();
  }, []);



  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <div
      style={{
        minHeight: "75VH",
        backgroundImage: `url(${BgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Helmet>
        <title>{title} - Marmasco</title>
      </Helmet>
      <ParticlesBackground />
      {loading
?<div className="marmasco-white" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh'}}>
<Loader />
</div>
:<div className="marmasco-animate-top">
      <div
        style={{
          backgroundImage: `url(${DomainsImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.85)",
            minHeight: "100%",
            width: "100%",
          }}
        >
          <div
            className="container-fluid marmasco-center marmasco-text-white"
            style={{
              marginTop: "-50px",
              paddingTop: "90px",
              paddingBottom: "90px",
            }}
          >
            <h1 style={{ textTransform: "uppercase" }}>{title}</h1>
            <p>{description}</p>
          </div>
        </div>
      </div>

      <div
        className="container-fluid marmasco-padding-large"
        style={{
          marginTop: "50px"
        }}
      >

<form onSubmit={handleSubmit} className="flex-form" style={{margin: '0 auto', border: '1px solid #ccc', borderRadius: '5px', overflow: 'hidden', padding: '30px', background: '#eee'}}>
      <div className="form-item" style={{marginRight: '20px', background: '#ddd', padding: '10px', borderRadius: '10px'}}>
      <label style={{display: 'block', marginBottom: '10px', marginLeft: '10px', position: 'relative'}}>
  Hosting Service
  <FontAwesomeIcon icon={faServer}  style={{position: 'absolute', right: '0', top: '50%', transform: 'translateY(-50%)'}} />
</label>
        <select value={hostingType} onChange={(e) => setHostingType(e.target.value)} style={{width: '100%', padding: '10px', border: 'none', background: 'transparent', cursor: 'pointer'}}>
          <option value="">Select service...</option>
          <option value="shared">Shared Hosting</option>
          <option value="vps">VPS Hosting</option>
        </select>
      </div>
      <div className="form-item" style={{marginRight: '20px', background: '#ddd', padding: '10px', borderRadius: '10px'}}>
      <label style={{display: 'block', marginBottom: '10px', marginLeft: '10px', position: 'relative'}}>
  Support Service
  <FontAwesomeIcon icon={faCog}  style={{position: 'absolute', right: '0', top: '50%', transform: 'translateY(-50%)'}} />
</label><select value={managementType} onChange={(e) => setManagementType(e.target.value)} style={{width: '100%', padding: '10px', border: 'none', background: 'transparent', cursor: 'pointer'}}>
          <option value="">Select service...</option>
          <option value="managed">Managed</option>
          <option value="self">Self-Managed</option>
        </select>
      </div>
      <div className="form-item" style={{marginRight: '20px', background: '#ddd', padding: '10px', borderRadius: '10px'}}>
      <label style={{display: 'block', marginBottom: '10px', marginLeft: '10px', position: 'relative'}}>
  Billing Cycle
  <FontAwesomeIcon icon={faMoneyBills}  style={{position: 'absolute', right: '0', top: '50%', transform: 'translateY(-50%)'}} />
</label>
        <select value={billingPeriod} onChange={(e) => setBillingPeriod(e.target.value)} style={{width: '100%', padding: '10px', border: 'none', background: 'transparent', cursor: 'pointer'}}>
          <option value="">Select cycle...</option>
          <option value="monthly">Monthly</option>
          <option value="quarterly">Quarterly</option>
          <option value="annually">Annually</option>
        </select>
      </div>
      <button className="form-item marmasco-center" type="submit" style={{padding: '10px', backgroundColor: '#009688', color: '#fff', border: 'none', borderRadius: '10px', cursor: 'pointer'}}><FontAwesomeIcon icon={faCalculator} /> CALCULATE PRICE</button>
    </form>
      </div>
      <div className="container-fluid" style={{marginTop: "150px"}}>
            <div className="row marmasco-padding-large">
      <div className="col-md-6">
          
        <div className="marmasco-justify">
          <p>
          Shared Hosting is a cost-effective and user-friendly option for individuals and small businesses looking to establish a strong online presence. At Marmasco, we offer superior shared hosting services that are perfect for beginners and those with moderate website traffic. Our shared hosting platform is built on robust infrastructure, ensuring your website is always up and running. We provide a user-friendly control panel, one-click installations for various applications, and 24/7 technical support. Our shared hosting plans come with ample storage, bandwidth, and email accounts, catering to your specific needs. We also offer a 99.9% uptime guarantee, ensuring your website is always accessible to your audience. With our shared hosting services, you can focus on growing your business while we take care of your hosting needs.
          </p>

          <p>
          Virtual Private Server (VPS) Hosting is an excellent choice for businesses that require more control and power than shared hosting. At Marmasco, we offer VPS hosting services that provide you with dedicated resources and full root access to your server. This means you can customise your server environment to suit your specific needs. Our VPS hosting plans are scalable, allowing you to upgrade your resources as your business grows. We use state-of-the-art hardware and software to ensure your website performs optimally at all times. Our VPS servers are also equipped with advanced security features to protect your data from threats. With our round-the-clock technical support, you can rest assured that help is always at hand should you encounter any issues.
          </p>

          <p>
          Server administration is a critical aspect of managing your online presence. At Marmasco, we offer comprehensive server administration services to ensure your server is always running smoothly. Our team of experienced server administrators will handle everything from server setup, software installation, security monitoring, and regular updates. We also provide troubleshooting services to quickly resolve any server issues that may arise. Our server administration services are designed to free up your time so you can focus on what you do best - running your business. We understand that every business has unique server requirements, and we are committed to providing customised solutions that align with your business objectives. With Marmasco, you can have peace of mind knowing your server is in capable hands.
          </p>
          </div>
    
        </div>

        <div className="col-md-6 grayscale">
                <img
                  src={HostingImg}
                  alt="Hosting"
                  className="centered-image"
                  style={{
                    height: "500px",
                    maxWidth: "100%",
                    objectFit: "contain",
                    borderRadius: "10px",
                  }}
                />
              </div>
      </div>
      </div>
      <div
        className="container-fluid marmasco-container"
        style={{
          paddingBottom: "80px",
          marginTop: "150px"
        }}
      >
 <div className="flex-container">
  <div className="flex-item">
    <FontAwesomeIcon icon={faServer} size="5x" color="#009688" />
    <h2>Web Hosting</h2>
    <p>Experience superior performance and speed with our reliable web hosting services, tailored to meet your needs.</p>
  </div>
  <div className="flex-item">
    <FontAwesomeIcon icon={faSync} size="5x" color="#009688" />
    <h2>Seamless Migration</h2>
    <p>Switching to us is easy. Enjoy a hassle-free migration process and get your website up and running in no time.</p>
  </div>
  <div className="flex-item">
    <FontAwesomeIcon icon={faTools} size="5x" color="#009688" />
    <h2>24/7 Support</h2>
    <p>Our dedicated support team is always available to help you with any issues or queries, ensuring your website is always at its best.</p>
  </div>
  <div className="flex-item">
    <FontAwesomeIcon icon={faShieldAlt} size="5x" color="#009688" />
    <h2>Advanced Security</h2>
    <p>Protect your website with our advanced security measures, including SSL certificates, DDoS protection, and regular backups.</p>
  </div>
</div>
      </div>

      <Modal isOpen={running} style={customStyles}>
        <div
          className="marmasco-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4 style={{ color: "#343a40" }}>Please wait...</h4>
          <br />
          <Loader />
        </div>
      </Modal>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="marmasco-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />

          <p className="marmasco-center">{message}</p>

          <br />
          <button
            className="marmasco-btn marmasco-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>

      <Top />
      </div>
}
    </div>
  );
};

export default Hosting;