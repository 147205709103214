import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Top from "../includes/topping";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullhorn,
  faChevronRight,
  faCogs,
  faGlobe,
  faServer,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Carousel } from "react-bootstrap";
import ParticlesBackground from "../includes/background";
import Modal from "react-modal";
import { runData } from "../../jsx/backend";
import Loader from "../includes/loader";
import SearchDomain from "../sections/searchDomain";
import BgImg from "../../img/bg.png";
import BgImgHome from "../../img/bg.jpg";
import domainImg from "../../img/domain-name.png";
import marketingImg from "../../img/digital-marketing.png";
import hostingImg from "../../img/web-hosting.png";
import systemsImg from "../../img/business-systems.png";
import homeImg from "../../img/business.png";
import ChatIcon from "../sections/chat.icon";

Modal.setAppElement("#root");

const Home = () => {
  const [title, setTitle] = useState("Loading");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);

  const pageTitle = async () => {
    const data = {
      page: "",
    };
    const responseData = await runData(data, `/page/`);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTitle(responseData.response.data.title);
          setLoading(false);
          break;

        default:
          setMessage(responseData.response.data.message);
          setTitle(responseData.response.data.message);
          setLoading(false);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setTitle(responseData.error.message);
      setLoading(false);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setTitle("An error occured while processing your request.");
      setLoading(false);
      setReporting(true);
    }
  };

  useEffect(() => {
    pageTitle();
  }, []);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <div
      style={{
        minHeight: "75VH",
        marginTop: "-75px",
        backgroundImage: `url(${BgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <ParticlesBackground />
      <Helmet>
        <title>{title} - Marmasco</title>
      </Helmet>
      {loading ? (
        <div
          className="marmasco-white"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="marmasco-animate-top">
          <div
            className="container-fluid  marmasco-white"
            style={{
              marginTop: "75px",
              backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.77) 100%, rgb(255, 255, 255) 100%), url(${BgImgHome})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="row">
              <div
                className="col-md-6 d-flex align-items-center justify-content-center text-center marmasco-padding-large"
                style={{
                  height: "70vh",
                }}
              >
                <div>
                  <h1>Marmasco Technologies</h1>
                  <h4>Empowering Your Digital Journey</h4>
                  <p>
                    At Marmasco Technologies, we believe in the transformative
                    power of technology. As your trusted partner, we're
                    committed to helping you navigate the digital landscape with
                    confidence and ease. Whether you're looking to revamp your
                    online presence, streamline your business processes, or
                    enhance your brand identity, our team of seasoned
                    professionals is here to turn your vision into reality.
                    Let's embark on this digital journey together and unlock new
                    possibilities for growth and success.
                  </p>
                  <Link
                    to="/contact/"
                    className="btn marmasco-color btn-block btn-outline-light"
                    style={{ minWidth: "250px", border: "none" }}
                  >
                    Let's Talk <FontAwesomeIcon icon={faChevronRight} />{" "}
                  </Link>
                </div>
              </div>
              <div
                className="col-md-6 marmasco-padding-large"
                style={{ height: "70VH" }}
              >
                <div
                  className="marmasco-white marmasco-card"
                  style={{ borderRadius: "30px", height: "100%" }}
                >
                  <Carousel data-bs-theme="dark">
                    <Carousel.Item>
                      <img
                        className="d-block w-100 img-fluid grayscale"
                        src={hostingImg}
                        alt="Web Hosting"
                        style={{
                          height: "60VH",
                          maxWidth: "100%",
                          borderRadius: "30px",
                          objectFit: "contain",
                          margin: "10px",
                          display: "block",
                        }}
                      />
                      <Carousel.Caption>
                        <div
                          style={{
                            backgroundColor: "rgba(0, 105, 92, 0.85)",
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <h3>Web Hosting</h3>
                          <p>Reliable and affordable web hosting services.</p>
                        </div>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100 img-fluid grayscale"
                        src={domainImg}
                        alt="Domain Management"
                        style={{
                          height: "60VH",
                          maxWidth: "100%",
                          borderRadius: "30px",
                          objectFit: "contain",
                          margin: "10px",
                          display: "block",
                        }}
                      />
                      <Carousel.Caption>
                        <div
                          style={{
                            backgroundColor: "rgba(0, 105, 92, 0.85)",
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <h3>Domain Management</h3>
                          <p>
                            Secure your online identity with our domain
                            management services.
                          </p>
                        </div>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100 img-fluid grayscale"
                        src={systemsImg}
                        alt="Business Systems"
                        style={{
                          height: "60VH",
                          maxWidth: "100%",
                          borderRadius: "30px",
                          objectFit: "contain",
                          margin: "10px",
                          display: "block",
                        }}
                      />
                      <Carousel.Caption>
                        <div
                          style={{
                            backgroundColor: "rgba(0, 105, 92, 0.85)",
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <h3>Business Systems</h3>
                          <p>
                            Streamline your business operations with our
                            efficient systems.
                          </p>
                        </div>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100  img-fluid grayscale"
                        src={marketingImg}
                        alt="Digital Marketing"
                        style={{
                          height: "60VH",
                          maxWidth: "100%",
                          borderRadius: "30px",
                          objectFit: "contain",
                          margin: "10px",
                          display: "block",
                        }}
                      />
                      <Carousel.Caption>
                        <div
                          style={{
                            backgroundColor: "rgba(0, 105, 92, 0.85)",
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <h3>Digital Marketing</h3>
                          <p>
                            Expand your reach with our comprehensive digital
                            marketing strategies.
                          </p>
                        </div>
                      </Carousel.Caption>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>

          <div
            className="container-fluid"
            style={{
              paddingBottom: "150px",
              paddingTop: "150px",
            }}
          >
            <h1 className="marmasco-center">Check Domain Availability</h1>
            <SearchDomain />
          </div>

          <div className="container-fluid">
            <div className="row marmasco-padding-large">
              <div className="col-md-6">
             
                <div className="marmasco-justify">
                  <p>
                    Welcome to Marmasco Technologies, your trusted partner in
                    digital transformation. We are a Zimbabwe-based IT company
                    specialising in providing innovative and efficient solutions
                    designed to revolutionise the way you conduct your business.
                    Our services range from domain registration and web hosting
                    to the development of comprehensive business systems and
                    strategic digital marketing. We are committed to delivering
                    tailored solutions that meet your unique needs, ensuring
                    that your operations are streamlined, productivity is
                    enhanced, and growth is driven.
                  </p>

                  <p>
                    Our domain registration and web hosting services are
                    designed to provide you with a robust online presence. We
                    understand the importance of a reliable and secure online
                    platform for your business. That's why we offer top-tier
                    domain registration services, ensuring your business stands
                    out in the digital landscape. Our web hosting services
                    guarantee optimal performance, security, and scalability for
                    your website, ensuring your customers enjoy a seamless
                    online experience.
                  </p>

                  <p>
                    At Marmasco Technologies, we also offer comprehensive
                    business systems solutions, including Inventory Management
                    Systems, AI Systems, Invoicing Systems, File Management
                    Systems, and Messaging Systems. These systems are designed
                    to automate and optimise your business processes, helping
                    you make data-driven decisions, improve customer service,
                    and enhance operational efficiency. Additionally, our
                    strategic digital marketing services are designed to
                    increase your brand visibility, engage your target audience,
                    and drive business growth. We utilise the latest digital
                    marketing strategies and tools to ensure your business
                    reaches its full potential.
                  </p>
                </div>
              </div>
              <div className="col-md-6 grayscale">
                <img
                  src={homeImg}
                  alt="Marmasco"
                  className="centered-image"
                  style={{
                    height: "500px",
                    maxWidth: "100%",
                    objectFit: "contain",
                    borderRadius: "10px",
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className="container-fluid"
            style={{
              paddingBottom: "80px",
              paddingTop: "80px",
            }}
          >
            <div className="flex-container">
              <div className="flex-item">
                <FontAwesomeIcon icon={faGlobe} size="5x" color="#009688" />
                <h2>Domain Registration</h2>
                <p>
                  Secure your online identity with our easy and affordable
                  domain registration services. Choose from a wide range of
                  domain extensions.
                </p>
              </div>
              <div className="flex-item">
                <FontAwesomeIcon icon={faServer} size="5x" color="#009688" />
                <h2>Web Hosting</h2>
                <p>
                  Experience superior performance and speed with our reliable
                  web hosting services, tailored to meet your needs.
                </p>
              </div>
              <div className="flex-item">
                <FontAwesomeIcon icon={faBullhorn} size="5x" color="#009688" />
                <h2>Digital Marketing</h2>
                <p>
                  Boost your online presence and reach your target audience with
                  our effective digital marketing strategies.
                </p>
              </div>
              <div className="flex-item">
                <FontAwesomeIcon icon={faCogs} size="5x" color="#009688" />
                <h2>Business Systems</h2>
                <p>
                  Streamline your business operations with our customised
                  business systems. Improve efficiency and productivity.
                </p>
              </div>
            </div>
          </div>

          <Modal
            isOpen={report}
            onRequestClose={() => setReporting(false)}
            style={customStyles}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#f8f9fa",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              }}
            >
              <h4>
                Message{" "}
                <span
                  onClick={() => setReporting(false)}
                  title="Close"
                  className="marmasco-right"
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faTimes} color="red" />
                </span>
              </h4>

              <hr />

              <p className="marmasco-center">{message}</p>

              <br />
              <button
                className="marmasco-btn marmasco-red"
                onClick={() => setReporting(false)}
                style={{ borderRadius: "30px", width: "100%" }}
              >
                CLOSE
              </button>
            </div>
          </Modal>

          <Top />
        </div>
      )}
    </div>
  );
};

export default Home;
