import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Top from "../../includes/topping";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalculator,
  faCog,
  faCommentDots,
  faComments,
  faFileInvoice,
  faFolderOpen,
  faMoneyBills,
  faRobot,
  faServer,
  faTimes,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import { runData } from "../../../jsx/backend";
import Loader from "../../includes/loader";
import SysImg from "../../../img/binary-red.jpg";
import aiImg from "../../../img/systems.png";
import BgImg from "../../../img/bg.png";
import ParticlesBackground from "../../includes/background";
import { Link } from "react-router-dom";
import { useMessageCount } from "../../../jsx/hooks/messages";
import { Badge } from "react-bootstrap";

Modal.setAppElement("#root");

const Systems = () => {
  const [title, setTitle] = useState("Loading");
  const [description, setDescription] = useState("Loading");
  const [running, setRunning] = useState(false);
  const [message, setMessage] = useState("");
  const [report, setReporting] = useState(false);
  const [hostingType, setHostingType] = useState('');
  const [managementType, setManagementType] = useState('');
  const [billingPeriod, setBillingPeriod] = useState('');
  const [loading, setLoading] = useState(true);
  const messageCount = useMessageCount();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here
  };

  const pageTitle = async () => {
    const data = {
      page: "systems",
    };
    const responseData = await runData(data, `/page/`);

    if (responseData.response) {
      switch (responseData.response.data.code) {
        case 200:
          setTitle(responseData.response.data.title);
          setDescription(responseData.response.data.description);
          setLoading(false);
          break;

        default:
          setMessage(responseData.response.data.message);
          setTitle(responseData.response.data.message);
          setLoading(false);
          setReporting(true);
      }
    } else if (responseData.error) {
      setMessage(responseData.error.message);
      setTitle(responseData.error.message);
      setLoading(false);
      setReporting(true);
    } else {
      setMessage("An error occured while processing your request.");
      setTitle("An error occured while processing your request.");
      setLoading(false);
      setReporting(true);
    }
  };

  useEffect(() => {
    pageTitle();
  }, []);



  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 10000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
      border: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "30VW",
    },
  };

  return (
    <div
      style={{
        minHeight: "75VH",
        backgroundImage: `url(${BgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Helmet>
        <title>{title} - Marmasco</title>
      </Helmet>
      <ParticlesBackground />
      {loading
?<div className="marmasco-white" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh'}}>
<Loader />
</div>
:<div className="marmasco-animate-top">
      <div
        style={{
          backgroundImage: `url(${SysImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.85)",
            minHeight: "100%",
            width: "100%",
          }}
        >
          <div
            className="marmasco-container marmasco-center marmasco-text-white"
            style={{
              marginTop: "-50px",
              paddingTop: "90px",
              paddingBottom: "90px",
            }}
          >
            <h1 style={{ textTransform: "uppercase" }}>{title}</h1>
            <p>{description}</p>
          </div>
        </div>
      </div>

      <div
        className="container-fluid marmasco-padding-large"
        style={{
          marginTop: "150px"
        }}
      >

<form onSubmit={handleSubmit} className="flex-form" style={{margin: '0 auto', border: '1px solid #ccc', borderRadius: '5px', overflow: 'hidden', padding: '30px', background: '#eee'}}>
      <div className="form-item" style={{marginRight: '20px', background: '#ddd', padding: '10px', borderRadius: '10px'}}>
      <label style={{display: 'block', marginBottom: '10px', marginLeft: '10px', position: 'relative'}}>
  Business System
  <FontAwesomeIcon icon={faServer}  style={{position: 'absolute', right: '0', top: '50%', transform: 'translateY(-50%)'}} />
</label>
        <select value={hostingType} onChange={(e) => setHostingType(e.target.value)} style={{width: '100%', padding: '10px', border: 'none', background: 'transparent', cursor: 'pointer'}}>
          <option value="">Select system...</option>
          <option value="ai">Artificial Intelligence</option>
          <option value="files">File Management</option>
          <option value="inventory">Inventory System</option>
          <option value="invoicing">Invoicing System</option>
          <option value="files">Messaging Service</option>
        </select>
      </div>
      <div className="form-item" style={{marginRight: '20px', background: '#ddd', padding: '10px', borderRadius: '10px'}}>
      <label style={{display: 'block', marginBottom: '10px', marginLeft: '10px', position: 'relative'}}>
  Support Service
  <FontAwesomeIcon icon={faCog}  style={{position: 'absolute', right: '0', top: '50%', transform: 'translateY(-50%)'}} />
</label><select value={managementType} onChange={(e) => setManagementType(e.target.value)} style={{width: '100%', padding: '10px', border: 'none', background: 'transparent', cursor: 'pointer'}}>
          <option value="">Select service...</option>
          <option value="managed">Managed</option>
          <option value="self">Self-Managed</option>
        </select>
      </div>
      <div className="form-item" style={{marginRight: '20px', background: '#ddd', padding: '10px', borderRadius: '10px'}}>
      <label style={{display: 'block', marginBottom: '10px', marginLeft: '10px', position: 'relative'}}>
  Billing Cycle
  <FontAwesomeIcon icon={faMoneyBills}  style={{position: 'absolute', right: '0', top: '50%', transform: 'translateY(-50%)'}} />
</label>
        <select value={billingPeriod} onChange={(e) => setBillingPeriod(e.target.value)} style={{width: '100%', padding: '10px', border: 'none', background: 'transparent', cursor: 'pointer'}}>
          <option value="">Select cycle...</option>
          <option value="monthly">Monthly</option>
          <option value="quarterly">Quarterly</option>
          <option value="annually">Annually</option>
        </select>
      </div>
      <button className="form-item marmasco-center" type="submit" style={{padding: '10px', backgroundColor: '#009688', color: '#fff', border: 'none', borderRadius: '10px', cursor: 'pointer'}}><FontAwesomeIcon icon={faCalculator} /> CALCULATE PRICE</button>
    </form>
      </div>

      <div
        className="container-fluid" style={{marginTop: "150px"}}
      >
      <div
        className="row marmasco-padding-large"
      >
        <div className="col-md-6">
        
                <div className="marmasco-justify">
          <p>At Marmasco Technologies, we pride ourselves on delivering innovative and efficient systems that are designed to revolutionise the way you conduct your business. Our solutions are tailored to meet your unique needs, ensuring that your operations are streamlined, productivity is enhanced, and growth is driven. We understand that in the fast-paced world of business, real-time information is crucial. That's why our Inventory Management Systems are designed to provide you with up-to-the-minute data on your stock levels, sales, orders, and deliveries. This not only enables you to manage your inventory efficiently but also helps to reduce errors and avoid stock-outs and overstock situations.</p>
          <p>In the digital age, artificial intelligence has become a game-changer in business operations. Our AI Systems utilise advanced machine learning algorithms to automate and optimise various business processes. These systems are designed to help you make data-driven decisions, predict market trends, and provide personalised experiences to your customers. We also understand the importance of efficient invoicing in maintaining a healthy cash flow. Our Invoicing Systems streamline the billing process, making it easier for you to create, send, and track invoices. This system is designed to help you get paid faster, improve cash flow, and reduce the time spent on manual invoicing tasks.</p>
          <p>At Marmasco Technologies, we believe that effective communication is key to building strong relationships with your customers. Our Messaging Systems, including WhatsApp Business integration, allow you to communicate with your customers in a fast and convenient way. This system is designed to help you provide better customer service, increase engagement, and build stronger relationships with your customers. Furthermore, our File Management Systems provide a secure and organised way to store, manage, and share your business documents. This system is designed to help you reduce clutter, improve collaboration, and ensure that your important files are always accessible and safe. Discover how our tailored solutions can transform your business today.</p>
        </div>
        </div>
        <div className="col-md-6 grayscale">
                <img
                  src={aiImg}
                  alt="Systems"
                  className="centered-image"
                  style={{
                    height: "500px",
                    maxWidth: "100%",
                    objectFit: "contain",
                    borderRadius: "10px",
                  }}
                />
              </div>
      </div>
      </div>
      <div
        className="container-fluid marmasco-container"
        style={{
          paddingBottom: "80px",
          marginTop: "150px"
        }}
      >
<div className="flex-container">
  <div className="flex-item">
    <FontAwesomeIcon icon={faWarehouse} size="5x" color="#009688" />
    <h4>Inventory Management Systems</h4>
    <p>Keep track of your stock levels, sales, orders, and deliveries in real-time. Manage your inventory efficiently and avoid stock-outs and overstock situations.</p>
  </div>
  <div className="flex-item">
    <FontAwesomeIcon icon={faRobot} size="5x" color="#009688" />
    <h4>AI Systems</h4>
    <p>Utilise advanced machine learning algorithms to automate and optimise various business processes. Make data-driven decisions and predict market trends.</p>
  </div>
  <div className="flex-item">
    <FontAwesomeIcon icon={faFileInvoice} size="5x" color="#009688" />
    <h4>Invoicing Systems</h4>
    <p>Streamline the billing process, making it easier for you to create, send, and track invoices. Get paid faster, improve cash flow, and reduce time spent on manual invoicing tasks.</p>
  </div>
  <div className="flex-item">
    <FontAwesomeIcon icon={faFolderOpen} size="5x" color="#009688" />
    <h4>File Management Systems</h4>
    <p>Provide a secure and organised way to store, manage, and share your business documents. Reduce clutter, improve collaboration, and ensure that your important files are always accessible and safe.</p>
  </div>
  <div className="flex-item">
    <FontAwesomeIcon icon={faComments} size="5x" color="#009688" />
    <h4>Messaging Systems</h4>
    <p>Communicate with your customers in a fast and convenient way, including WhatsApp Business integration. Provide better customer service, increase engagement, and build stronger relationships with your customers.</p>
  </div>
</div>
      </div>

      <Modal isOpen={running} style={customStyles}>
        <div
          className="marmasco-center"
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4 style={{ color: "#343a40" }}>Please wait...</h4>
          <br />
          <Loader />
        </div>
      </Modal>

      <Modal
        isOpen={report}
        onRequestClose={() => setReporting(false)}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#f8f9fa",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <h4>
            Message{" "}
            <span
              onClick={() => setReporting(false)}
              title="Close"
              className="marmasco-right"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} color="red" />
            </span>
          </h4>

          <hr />

          <p className="marmasco-center">{message}</p>

          <br />
          <button
            className="marmasco-btn marmasco-red"
            onClick={() => setReporting(false)}
            style={{ borderRadius: "30px", width: "100%" }}
          >
            CLOSE
          </button>
        </div>
      </Modal>

      <Top />
      </div>
}
    </div>
  );
};

export default Systems;