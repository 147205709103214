import React from 'react';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

function Loader() {
const override = css`
  display: block;
  margin: 0 auto;
  border-color: grey;`;

return (
<div className="mm-content mm-center">
<ClipLoader css={override} size={75} />
</div>
);}

export default Loader;